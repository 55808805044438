<template>
  <div :class="`flex flex-col space-y-2 ${defaultSize}`">
    <f-normal-text v-if="hasTitle">
      <slot name="title" />
    </f-normal-text>
    <div
      :class="`flex relative flex-row items-center space-x-1 justify-between w-full rounded ${customClass} bg-grayBackground ${paddings}`"
      :id="'container' + tabIndex"
      @focus="
        showOption = true;
        isFocused = true;
        ShowSelectModal = true;
      "
      @blur="
        isFocused = false;
        autoComplete ? '' : (showOption = false);
      "
      :tabindex="tabIndex"
      @click="ShowSelectModal = true"
    >
      <div class="w-full flex flex-row space-x-1 items-center justify-between">
        <input
          ref="select"
          :value="withKey ? valueData : textValue"
          :placeholder="placeholder"
          disabled
          :class="` text-black flex-grow bg-transparent placeholder-primaryGray focus input w-full focus:outline-none  `"
        />
        <f-icon
          @click="
            showOption = true;
            isFocused = true;
          "
          name="dropdown"
          custom-class="md:h-[7px] h-[6px] cursor-pointer"
        />
      </div>

      <!-- For larger screens -->
      <div
        v-if="showOption"
        @click.stop="null"
        :class="`w-full flex flex-col top-[101%] right-0 xs:hidden sm:hidden bg-white z-50 max-h-[200px] min-w-[120px] overflow-y-auto rounded-md shadow-md absolute`"
      >
        <div
          class="w-full py-2 sticky top-0 bg-white flex flex-row items-center justify-between px-2"
          v-if="autoComplete"
        >
          <f-text-field
            placeholder="Search"
            v-model="searchValue"
            :custom-class="'!w-full !rounded-[8px] !border-neutral_grey_light !border-[1px]'"
          >
          </f-text-field>
          <!-- <span class="pl-3 cursor-pointer" @click.stop="showOption = false">
            <f-icon :name="'cross-circle'" :customClass="'h-[19px]'" />
          </span> -->
        </div>
        <div
          class="py-2 px-3 w-full flex flex-row items-center hover:!bg-gray-100 cursor-pointer"
          v-for="(item, index) in searchResult"
          :key="index"
          @click.stop="
            selectValue(item);
            showOption = false;
          "
        >
          <template v-if="isMultiple">
            <div class="w-full flex flex-row space-x-3 items-center">
              <f-icon
                :name="
                  itemIsSelected(item.key) ? 'checkbox-active' : 'checkbox'
                "
                custom-class="h-[16px]"
                v-if="itemIsSelected(item.key)"
              />
              <span
                v-else
                class="w-[17px] h-[17px] rounded-[3px] border-[1px] border-black"
              >
              </span>
              <f-normal-text>
                {{ item.value }}
              </f-normal-text>
            </div>
          </template>
          <f-normal-text v-else>
            {{ item.value }}
          </f-normal-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { SelectOption } from "../../types";
import { capitalize, defineComponent, onMounted, ref, watch } from "vue";
import FIcon from "../Icon.vue";
import FNormalText from "../Typography/normalText.vue";
import FTextField from "./textField.vue";
import { searchArray } from "../../composables";

export default defineComponent({
  name: "FSelect",
  components: {
    FIcon,
    FNormalText,
    FTextField,
  },
  props: {
    withKey: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array as () => SelectOption[],
    },
    paddings: {
      type: String,
      default: "py-4 px-3",
    },
    padding: {
      type: String,
      default: "py-4 px-3",
    },
    customClass: {
      type: String,
      default: "",
    },
    defaultValues: {
      required: false,
      default: [],
    },
    modelValue: {
      type: String || Array,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    hasTitle: {
      type: Boolean,
      default: false,
    },
    defaultSize: {
      type: String,
      default: "w-full",
    },
    autoComplete: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "OnOptionSelected", "OnSearch"],
  setup(props: any, context: any) {
    const isFocused = ref(false);
    const showOption = ref(false);

    const tabIndex = Math.random();

    const ShowSelectModal = ref(false);

    const OptionRef = ref<SelectOption[]>([]);

    const searchResult = ref<SelectOption[]>([]);

    const selectedKey = ref();

    const valueData = ref("");

    const textValue = ref("");

    const searchValue = ref("");

    const selectOptions = ref<any[]>([]);

    const prepareSelectOptions = () => {
      selectOptions.value.length = 0;

      if (Array.isArray(searchResult.value)) {
        searchResult.value.forEach((item: any) => {
          selectOptions.value.push({
            key: item.key,
            value: `${item.value}${props.withKey ? ` (${item.key})` : ""}`,
            hasIcon: item.hasIcon ? item.hasIcon : false,
            extras: item.extras ? item.extras : "",
            isImage: item.isImage ? item.isImage : false,
          });
        });
      }
    };

    watch(selectedKey, () => {
      if (selectedKey.value != 0) {
        const selectedOption = searchResult.value.filter((eachItem: any) => {
          return eachItem.key == selectedKey.value;
        });
        selectValue(selectedOption[0]);
        ShowSelectModal.value = false;
      }
    });

    const selectedItems = ref<any>([]);

    watch(props, () => {
      if (props.options) {
        OptionRef.value = props.options;
      }
      prepareSelectOptions();
    });

    onMounted(() => {
      if (props.defaultValues.length > 0) {
        selectedItems.value = props.defaultValues;
      }
      if (props.options) {
        OptionRef.value = props.options;
        searchResult.value = props.options;
      }

      if (props.modelValue) {
        const selectedOption = searchResult.value.filter((eachItem: any) => {
          return eachItem.key == props.modelValue;
        });
        selectedKey.value = props.modelValue;
        if (selectedOption.length > 0) {
          selectValue(selectedOption[0]);
        }
      }
      prepareSelectOptions();
    });

    const itemIsSelected = (inputKey: string) => {
      const item = selectedItems.value.filter((key: any) => {
        return key == inputKey;
      });

      return item.length > 0;
    };

    const selectValue = (option: any) => {
      if (props.autoComplete) {
        context.emit("OnOptionSelected", option.key);

        isFocused.value = false;
        showOption.value = false;

        if (props.withKey) {
          valueData.value = option.key;
        } else {
          valueData.value = option.value;
          textValue.value = option.value;
        }

        context.emit("update:modelValue", option.key);

        document.getElementById("container" + tabIndex)?.blur();
        return;
      }
      if (props.isMultiple) {
        if (itemIsSelected(option.key)) {
          selectedItems.value = selectedItems.value.filter((key: any) => {
            return key != option.key;
          });

          return;
        }
        selectedItems.value.push(option.key);
        context.emit("update:modelValue", selectedItems.value);
        context.emit("OnOptionSelected", option.key);
      } else {
        context.emit("update:modelValue", option.key);
        context.emit("OnOptionSelected", option.key);
        if (props.withKey) {
          valueData.value = option.key;
        } else {
          valueData.value = option.value;
          textValue.value = option.value;
        }
        isFocused.value = false;
        showOption.value = false;

        document.getElementById("container" + tabIndex)?.blur();
      }
    };

    const getSelectedOption = (keyValue: any) => {
      const option = searchResult.value.filter((eachItem: any) => {
        return eachItem.key == keyValue;
      });

      return option[0].value;
    };

    const searchOption = () => {
      if (props.autoComplete) {
        searchResult.value = searchArray(
          OptionRef.value,
          capitalize(searchValue.value)
        );
      }
    };

    watch(searchValue, () => {
      searchOption();
    });

    watch(OptionRef, () => {
      searchOption();
    });

    watch(searchResult, () => {
      prepareSelectOptions();
    });

    return {
      showOption,
      valueData,
      isFocused,
      selectValue,
      tabIndex,
      textValue,
      itemIsSelected,
      selectedItems,
      getSelectedOption,
      ShowSelectModal,
      selectOptions,
      selectedKey,
      searchValue,
      searchResult,
    };
  },
});
</script>
