<template>
  <form id="form-wrapper">
    <slot />
  </form>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  components: {},
  props: {
    parentRefs: {
      required: true,
    },
  },
  name: "FFormWrapper",
  setup(props: any) {
    const formWrapper = ref<any>(null);

    const fieldsToValidate = ref<any[]>();

    watch(props, () => {
      fieldsToValidate.value = props.parentRefs;
    });

    const validate = () => {
      let formIsValid = true;

      for (const key in fieldsToValidate.value) {
        const componentName: any = key;

        const element = fieldsToValidate.value[componentName];
        if (element[0]) {
          if (element[0]) {
            if ("checkValidation" in element[0]) {
              element[0].checkValidation();
              formIsValid = formIsValid && element[0].validationStatus;
            }
          }
        }
      }
      return formIsValid;
    };

    watch(props, () => {
      fieldsToValidate.value = props.parentRefs;
    });

    return {
      formWrapper,
      validate,
      fieldsToValidate,
    };
  },
});
</script>
