import * as d3 from 'd3'
import { convertToMoney } from '.'
import moment from 'moment-timezone'
import { ref } from 'vue'
import { ChartLabel, Indicator } from '@/types'

export const showUnvisitedPDArray = ref(false)

const getEquivalentScale = (ticker: number, X: any[]) => {
  if (ticker > X.length) {
    ticker = X.length
  }
  const equivalentXScale: number[] = []
  d3.range(ticker)
    .map((number) => number + 1)
    .forEach((value) => {
      const equivalentID = Math.floor((value * X.length) / ticker)

      equivalentXScale.push(X[equivalentID - 1])
    })

  return equivalentXScale
}

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/candlestick-chart
export const CandlestickChart = (
  defaultSvg: d3.Selection<SVGSVGElement, undefined, null, undefined>,
  data: {
    Date: any
    Open: number
    High: number
    Low: number
    Close: number
    id?: number
  }[],
  indicators: Indicator[],
  allLables: ChartLabel[],
  {
    date = (d: { Date: any }) => new Date(d.Date).getTime(), // given d in data, returns the (temporal) x-value
    open = (d: { Open: number }) => d.Open, // given d in data, returns a (quantitative) y-value
    close = (d: { Close: number }) => d.Close, // given d in data, returns a (quantitative) y-value
    high = (d: { High: number }) => d.High, // given d in data, returns a (quantitative) y-value
    low = (d: { Low: number }) => d.Low, // given d in data, returns a (quantitative) y-value
    title, // given d in data, returns the title text
    marginTop = 20, // top margin, in pixels
    marginRight = 30, // right margin, in pixels
    marginBottom = 30, // bottom margin, in pixels
    marginLeft = 40, // left margin, in pixels
    width = 640, // outer width, in pixels
    height = 400, // outer height, in pixels
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    xDomain, // array of x-values (defaults to every weekday)
    xPadding = 0.2,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    xTicks, // array of x-values to label (defaults to every other Monday)
    yType = d3.scaleLinear, // type of y-scale
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yDomain, // [ymin, ymax]
    xFormat = 'ddd MMM D, YY hh:mma', // a format specifier for the date on the x-axis
    yFormat = '~f', // a format specifier for the value on the y-axis
    yLabel = '', // a label for the y-axis
    stroke = 'currentColor', // stroke color for the daily rule
    strokeLinecap = 'butt', // stroke line cap for the rules
    colors = ['#4daf4a', '#999999', '#e41a1c'], // [up, no change, down]
    timeline = 'month',
    additionalDataCount = 100,
    uniqueCode = 0,
    chartState = {
      leftBoundaryMet: false,
      rightBoundaryMet: false,
    },
    zoomValue = 6,
    lastCandleId = 0,
    fromReload = false,
  } = {
    title: undefined,
  },
) => {
  // Compute values.
  const X = d3.map(data, date)
  const Yo = d3.map(data, open)
  const Yc = d3.map(data, close)
  const Yh = d3.map(data, high)
  const Yl = d3.map(data, low)
  const I = d3.range(X.length)

  let initialXBandStep = 0

  const maxXTicker = 8

  let equivalentXScale: number[] = []

  let zoomExtent = 7

  let visibleCandles: number[] = []

  const dataLength = X.length

  if (dataLength < 54) {
    zoomExtent = 7
  } else if (dataLength > 200 && dataLength < 500) {
    zoomExtent = 10
  } else if (dataLength >= 500 && dataLength < 1000) {
    zoomExtent = 15
  } else if (dataLength >= 1000) {
    zoomExtent = 25
  }

  let newYScaleData: any = undefined

  const xRange = [marginLeft, width - marginRight] // [left, right]
  const yRange = [height - marginBottom, marginTop] // [bottom, top]

  // Compute default domains and ticks.
  if (xDomain === undefined) {
    if (timeline == 'daytime') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      xDomain = X
    } else {
      xDomain = X
    }
  }
  if (yDomain === undefined) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yDomain = [d3.min(Yl) || 0, d3.max(Yh) || 0]
  }
  if (xTicks === undefined) {
    // xTicks = weeks(d3.min(xDomain), d3.max(xDomain), 2) || []

    equivalentXScale = getEquivalentScale(maxXTicker, X)

    if (timeline == 'daytime') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      xTicks = equivalentXScale
    } else {
      xTicks = equivalentXScale
    }
  }

  // Construct scales and axes.
  // If you were to plot a stock using d3.scaleUtc, you’d see distracting gaps
  // every weekend. This chart therefore uses a d3.scaleBand whose domain is every
  // weekday in the dataset. A few gaps remain for holiday weekdays, such as
  // Christmas, but these are infrequent and allow the labeling of Mondays. As a
  // band scale, we specify explicit tick values.
  const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding)

  const yScale = yType(yDomain, yRange)
  const xAxis = d3
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .axisBottom(xScale)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .tickFormat((value) => {
      return (
        moment(parseFloat(value.toString()))
          // .tz('America/New_York')
          .format(xFormat)
      )
    })
    .tickValues(xTicks)
  const yAxis = d3.axisLeft(yScale).ticks(height / 40, yFormat)

  // Compute titles.
  if (title === undefined) {
    // const formatDate = d3.utcFormat('%a, %B %-d, %Y - %I:%M %p')

    const formatChange = ((f) => (y0: number, y1: number) => f((y1 - y0) / y0))(
      d3.format('+.2%'),
    )
    const finalTitle: any = (i: any) => `${moment(new Date(X[i]))
      // .tz('America/New_York')
      .format('ddd D MMM, YYYY - hh:mma')}
Open: ${convertToMoney(Yo[i], false, 'usd')}
Close: ${convertToMoney(Yc[i], false, 'usd')} (${formatChange(Yo[i], Yc[i])})
Low: ${convertToMoney(Yl[i], false, 'usd')}
High: ${convertToMoney(Yh[i], false, 'usd')}`

    title = finalTitle
  } else if (title !== null) {
    const T = d3.map(data, title)
    const finalTitle: any = (i: any) => T[i]
    title = finalTitle
  }

  const handleMouseMove = (mouse: any) => {
    // move the vertical line
    d3.select(`.mouse-line${uniqueCode}`).attr('d', function () {
      let d = 'M' + (mouse[0] + marginLeft) + ',' + (height - marginBottom)
      d += ' ' + (mouse[0] + marginLeft) + ',' + 0
      return d
    })

    // for x
    const xRange = xScale.range()
    const currentRange = xRange[1] - xRange[0]

    const fixedRange = width - marginRight - marginLeft

    const currentBand = (initialXBandStep * currentRange) / fixedRange

    const index = Math.round(mouse[0] / currentBand)

    if (index >= 0) {
      const xVal = visibleCandles[index]

      if (xVal) {
        // Move vertical text and box
        d3.select(`.mouse-line-vertical-text${uniqueCode}`)
          .text(
            `${moment(parseInt(xVal.toString())).format(
              'MMM D, YYYY - hh:mma',
            )}`,
          )
          .attr(
            'transform',
            `translate(${mouse[0] + 28}, ${
              height - marginBottom - 20
            }) rotate(-90)`,
          )

        d3.select(`.mouse-line-vertical-box${uniqueCode}`)
          .attr('x', mouse[0] + 10)
          .attr('y', height - marginBottom - 150)
      }
    }

    // move the horizontal line
    d3.select(`.mouse-line-horizontal${uniqueCode}`).attr('d', function () {
      let d = 'M' + width + ',' + mouse[1]
      d += ' ' + (0 + marginLeft) + ',' + mouse[1]
      return d
    })

    const yValue = newYScaleData
      ? newYScaleData.invert(mouse[1])
      : yScale.invert(mouse[1])

    // Move horizontal text and box

    d3.select(`.mouse-line-horizontal-text${uniqueCode}`)
      .text(`${convertToMoney(yValue, false, 'usd')}`)
      .attr(
        'transform',
        `translate(${width - marginRight - marginLeft}, ${mouse[1] - 10})`,
      )

    d3.select(`.mouse-line-horizontal-box${uniqueCode}`)
      .attr('x', width - marginRight - marginLeft - 8)
      .attr('y', mouse[1] - 24)
  }

  let svg = d3
    .create('svg')
    .attr('width', width)
    .attr('class', `cursor-crosshair${uniqueCode}`)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height])
    .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')

  if (defaultSvg) {
    svg = defaultSvg
  }

  // Add a clipPath: everything out of this area won't be drawn.
  if (defaultSvg == null) {
    svg
      .append('defs')
      .append('SVG:clipPath')
      .attr('id', `clip${uniqueCode}`)
      .append('SVG:rect')
      .attr('width', width)
      .attr('height', height)
      .attr('x', 0)
      .attr('y', 0)
  }

  // Create the scatter variable: where both the candles and the brush take place
  let scatter: any = undefined

  if (defaultSvg == null) {
    scatter = svg
      .append('g')
      .attr('class', `scatter${uniqueCode}`)
      .attr('clip-path', `url(#clip${uniqueCode})`)
  } else {
    scatter = svg.select('.scatter')
  }

  let xAxisGroup: any = undefined

  if (defaultSvg) {
    xAxisGroup = svg.append('g').attr('class', `x-axis${uniqueCode} x-axis`)
  } else {
    xAxisGroup = svg.append('g').attr('class', `x-axis${uniqueCode} x-axis`)
  }

  xAxisGroup
    .attr('transform', `translate(0,${height - marginBottom})`)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .call(xAxis)
    .call((g: any) => g.select('.domain').remove())

  let yAxisGroup: any = undefined

  if (!defaultSvg) {
    yAxisGroup = svg.append('g').attr('class', `y-axis${uniqueCode} y-axis`)

    yAxisGroup
      .attr('transform', `translate(${marginLeft},0)`)
      .call(yAxis)
      .call((g: any) => g.select('.domain').remove())
      .call((g: any) =>
        g
          .append('text')
          .attr('x', -marginLeft)
          .attr('y', 10)
          .attr('fill', 'currentColor')
          .attr('text-anchor', 'start')
          .text(yLabel),
      )
  }

  // zoom setup

  const extent: any = [
    [marginLeft, marginTop],
    [width - marginRight, height - marginTop],
  ]

  const zoomed = (event: any) => {
    // update mouse cursor pointer

    // prevent zoom and pan when shift key is pressed
    if (event?.sourceEvent?.shiftKey) {
      return
    }
    // for xAxis
    const expansionExtent = Math.floor(event.transform.k)

    const newMaxXTicker = maxXTicker + expansionExtent * 4

    const newEquivalentXScale = getEquivalentScale(newMaxXTicker, X)

    xAxis.tickValues(newEquivalentXScale)

    const newXRange = [marginLeft, width - marginRight].map((d) =>
      event.transform.applyX(d),
    )

    xScale.range(newXRange)

    svg
      .selectAll(`.x-axis${uniqueCode}`)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .call(xAxis)
      .call((g: any) => g.select('.domain').remove())

    // for yAxis
    const newYScale = event.transform.rescaleY(yScale)
    yAxis.scale(newYScale)

    newYScaleData = newYScale

    svg
      .selectAll(`.y-axis${uniqueCode}`)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .call(yAxis)
      .call((g: any) => g.select('.domain').remove())

    // for candleSticks
    svg
      .selectAll(`.candleStick${uniqueCode} g`)
      .attr(
        'transform',
        (i: any) =>
          `translate(${(xScale(X[i]) || 0) + xScale.bandwidth() / 2},0)`,
      )
      .select(`.candleStickBar${uniqueCode}`)
      .attr('stroke-width', xScale.bandwidth())
      .attr('y1', (i: any) => newYScale(Yo[i]))
      .attr('y2', (i: any) => newYScale(Yc[i]))
      .attr('stroke', (i: any) => {
        if (allLables.length > 0) {
          let color = colors[1 + Math.sign(Yo[i] - Yc[i])]

          allLables.forEach((lable) => {
            if (lable.candles.includes(data[i].id || 0)) {
              color = lable.color
            }
          })

          return color
        } else {
          return colors[1 + Math.sign(Yo[i] - Yc[i])]
        }
      })
    svg
      .selectAll(`.candleStick${uniqueCode} g`)
      .select(`.candleStickWick${uniqueCode}`)
      .attr('y1', (i: any) => newYScale(Yl[i]))
      .attr('y2', (i: any) => newYScale(Yh[i]))
      .attr('stroke', (i: any) => {
        if (allLables.length > 0) {
          let color = 'black'

          allLables.forEach((lable) => {
            if (lable.candles.includes(data[i].id || 0)) {
              color = lable.color
            }
          })

          return color
        } else {
          return 'black'
        }
      })

    // for the mouse event
    mouseG
      .select(`.event-container${uniqueCode}`)
      .attr('width', newXRange[1] - newXRange[0])
      .attr(
        'transform',
        `translate(${newXRange[0] + xScale.bandwidth() / 2},0)`,
      )

    const xStep = xScale.step()

    const leftDisplacementPoint = marginLeft - newXRange[0]
    const rightDisplacementPoint = newXRange[1] - (width - marginRight)

    const candlesToTheLeft = Math.floor(leftDisplacementPoint / xStep)
    const candlesToTheRight = Math.floor(rightDisplacementPoint / xStep)

    if (leftDisplacementPoint == 0) {
      chartState.leftBoundaryMet = true
    } else {
      chartState.leftBoundaryMet = false
    }

    if (rightDisplacementPoint == 0) {
      chartState.rightBoundaryMet = true
    } else {
      chartState.rightBoundaryMet = false
    }

    visibleCandles = X.slice(candlesToTheLeft, X.length - candlesToTheRight)

    // indicators
    // drawIndicator(newYScale)
  }

  const zoomStarted = () => {
    hideCrosschairAndLabel()
  }

  const zoomEnded = () => {
    showCrosschairAndLabel()
  }

  const zoom = d3
    .zoom()
    .scaleExtent([2, zoomExtent])
    .translateExtent(extent)
    .extent(extent)
    .on('zoom', zoomed)
    .on('start', zoomStarted)
    .on('end', zoomEnded)

  const zoomToCandle = (id: any) => {
    // Get last candle
    const CandleGroup = svg.select(`#candleBody${id}`)
    const lastCandleBar = svg.select(`#candleBodyBar${id}`)

    if (CandleGroup) {
      if (CandleGroup.node() && lastCandleBar.node()) {
        // Get the bounding box of the target element
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore

        let transformProperty = CandleGroup.attr('transform')
        transformProperty = transformProperty
          .replace('translate(', '')
          .replace(')', '')

        const xPosition = parseFloat(transformProperty.split(',')[0])

        const y1BarPosition = parseFloat(lastCandleBar.attr('y2'))

        setTimeout(() => {
          zoom.translateTo(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            svg,
            xPosition,
            y1BarPosition,
          )
        }, 500)

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        zoom.scaleTo(svg, zoomValue)
      }
      //
    }
  }

  const zoomSetup = (
    svg: d3.Selection<SVGSVGElement, undefined, null, undefined>,
  ) => {
    svg.call(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      zoom,
    )

    initialXBandStep = xScale.step()

    if (!defaultSvg) {
      if (fromReload) {
        zoomToCandle(lastCandleId)
      } else {
        if (allLables.length) {
          zoomToCandle(allLables[0].candles[0])
        } else {
          zoomToCandle(lastCandleId)
        }
      }
    } else {
      let currentXPosition = X.length - additionalDataCount
      currentXPosition = currentXPosition >= 0 ? currentXPosition : 0
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      zoom.translateTo(defaultSvg, xScale(X[currentXPosition]), height / 2)
    }
  }

  if (defaultSvg == null) {
    const g = scatter
      .append('g')
      .attr('stroke', stroke)
      .attr('class', `candleStick${uniqueCode}`)
      .attr('stroke-linecap', strokeLinecap)
      .selectAll('g')
      .data(I)
      .join('g')
      .attr(
        'transform',
        (i: any) =>
          `translate(${(xScale(X[i]) || 0) + xScale.bandwidth() / 2},0)`,
      )
      .attr('id', (i: any) => {
        return `candleBody${data[i].id}`
      })

    g.append('line')
      .attr('class', `candleStickWick${uniqueCode}`)
      .attr('y1', (i: any) => yScale(Yl[i]))
      .attr('y2', (i: any) => yScale(Yh[i]))

    g.append('line')
      .attr('class', `candleStickBar${uniqueCode}`)
      .attr('y1', (i: any) => yScale(Yo[i]))
      .attr('y2', (i: any) => yScale(Yc[i]))
      .attr('id', (i: any) => {
        return `candleBodyBar${data[i].id}`
      })
      .attr('stroke-width', xScale.bandwidth())
      .attr('stroke', (i: any) => colors[1 + Math.sign(Yo[i] - Yc[i])])

    if (title)
      g.append('title').attr('class', `candleTitle${uniqueCode}`).text(title)

    // Add indicators
  } else {
    const candleStickContainer = scatter.select(`.candleStick${uniqueCode}`)

    if (!newYScaleData) {
      newYScaleData = yScale
    }

    candleStickContainer
      .selectAll('g')
      .data(I)
      .join('g')
      .attr(
        'transform',
        (i: any) =>
          `translate(${(xScale(X[i]) || 0) + xScale.bandwidth() / 2},0)`,
      )

    candleStickContainer
      .select(`.candleStickWick${uniqueCode}`)
      .attr('y1', (i: any) => newYScaleData(Yl[i]))
      .attr('y2', (i: any) => newYScaleData(Yh[i]))

    candleStickContainer
      .select(`.candleStickBars${uniqueCode}`)
      .attr('y1', (i: any) => newYScaleData(Yo[i]))
      .attr('y2', (i: any) => newYScaleData(Yc[i]))
      .attr('stroke-width', xScale.bandwidth())
      .attr('stroke', (i: any) => {
        return colors[1 + Math.sign(Yo[i] - Yc[i])]
      })

    if (title)
      candleStickContainer.select(`.candleTitle${uniqueCode}`).text(title)
  }

  // append a g for all the mouse over events

  let mouseG = d3.select(`.mouse-over-effects${uniqueCode}`)

  if (!defaultSvg) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mouseG = svg.append('g').attr('class', `mouse-over-effects${uniqueCode}`)
  }

  const hideCrosschairAndLabel = () => {
    d3.select(`.mouse-line${uniqueCode}`).style('opacity', '0')
    d3.select(`.mouse-line-horizontal${uniqueCode}`).style('opacity', '0')
    d3.select(`.mouse-line-horizontal-text${uniqueCode}`).style('opacity', '0')
    d3.select(`.mouse-line-vertical-text${uniqueCode}`).style('opacity', '0')
    d3.select(`.mouse-line-horizontal-box${uniqueCode}`)
      .style('opacity', 0)
      .attr('width', 0)
      .attr('height', 0)
    d3.select(`.mouse-line-vertical-box${uniqueCode}`)
      .style('opacity', 0)
      .attr('width', 0)
      .attr('height', 0)
  }

  const showCrosschairAndLabel = () => {
    d3.select(`.mouse-line${uniqueCode}`).style('opacity', '1')
    d3.select(`.mouse-line-horizontal${uniqueCode}`).style('opacity', '1')
    d3.select(`.mouse-line-horizontal-text${uniqueCode}`).style('opacity', '1')
    d3.select(`.mouse-line-vertical-text${uniqueCode}`).style('opacity', '1')
    d3.select(`.mouse-line-horizontal-box${uniqueCode}`)
      .attr('width', 110)
      .attr('height', 20)
      .style('display', 'block')
      .style('opacity', '1')
    d3.select(`.mouse-line-vertical-box${uniqueCode}`)
      .attr('width', 30)
      .attr('height', 135)
      .style('display', 'block')
      .style('opacity', '1')
  }

  // this is the vertical line but hidden
  mouseG
    .append('path')
    .attr('class', `mouse-line${uniqueCode} mouse-line`)
    .style('stroke', 'black')
    .style('stroke-width', '1px')
    .style('opacity', '0')

  // horizontal line
  mouseG
    .append('path')
    .attr('class', `mouse-line-horizontal${uniqueCode} mouse-line-horizontal`)
    .style('stroke', 'black')
    .style('stroke-width', '1px')
    .style('opacity', '0')

  // rect to capture mouse movements
  mouseG
    .append('svg:rect')
    .attr('width', width - marginRight - marginLeft)
    .attr('height', height - marginBottom)
    .attr('fill', 'none')
    .attr('pointer-events', 'all')
    .attr('transform', `translate(${marginLeft + xScale.bandwidth() / 2},0)`)
    .on('mouseout', function () {
      // on mouse out hide line and text
      hideCrosschairAndLabel()
    })
    .on('mouseover', function () {
      // on mouse in show line and text
      showCrosschairAndLabel()
    })
    .on('mousemove', function (event) {
      const mouse = d3.pointer(event)
      handleMouseMove(mouse)
    })

  if (!defaultSvg) {
    // horizontal line text  and box for price actions

    svg
      .append('rect')
      .attr('width', 0)
      .attr('height', 0)
      .attr('fill', 'white')
      .style('opacity', 0)
      .attr('class', `mouse-line-horizontal-box${uniqueCode}  `)

    svg
      .append('text')
      .attr(
        'class',
        `mouse-line-horizontal-text${uniqueCode} mouse-line-horizontal-text `,
      )
      .style('opacity', '0')

    // vertical line text and box

    svg
      .append('rect')
      .attr('width', 0)
      .attr('height', 0)
      .attr('fill', 'white')
      .style('opacity', 0)
      .attr('class', `mouse-line-vertical-box${uniqueCode}  `)

    svg
      .append('text')
      .attr(
        'class',
        `mouse-line-vertical-text${uniqueCode} mouse-line-vertical-text `,
      )
      .style('opacity', '0')

    // Hide both boxes
    setTimeout(() => {
      hideCrosschairAndLabel()
    }, 700)
  }

  // drawIndicator()

  svg.call(zoomSetup)

  return {
    node: svg.node(),
    element: svg,
    zoomToCandle,
  }
}
