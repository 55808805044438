<template>
  <div class="fixed h-full flex flex-row w-full">
    <!-- Control Bar -->
    <div class="lg:w-[17%] mdlg:w-[21%] flex flex-col h-full">
      <f-control-bar
        :controlOptions="controlOptions"
        v-model="selectedControlKey"
      />
    </div>
    <!-- Window -->
    <div
      class="lg:w-[83%] mdlg:w-[79%] flex flex-col space-y-2 h-full py-4 pt-1 px-4"
    >
      <!-- Floating elements -->
      <div
        class="py-1 sticky top-0 left-0 w-full flex flex-row items-center space-x-3"
      >
        <div
          :class="`bg-white py-2 px-3 rounded-[7px] shadow-md cursor-pointer border-[2px] ${
            element.key == currentSelectFloatingWindow
              ? 'border-purple-400'
              : 'border-transparent'
          }`"
          v-for="(element, index) in windowFloatingElements"
          :key="index"
          @click="currentSelectFloatingWindow = element.key"
        >
          <normal-text :htmlContent="element.title"> </normal-text>
        </div>
      </div>

      <!-- Windon content -->
      <div class="w-full h-full grid grid-cols-12 gap-3 bg-white px-3 py-3">
        <!-- Chart Containers -->
        <template
          v-for="(containers, index) in windowChartContainers"
          :key="index"
        >
          <!-- For chart containers -->
          <template v-if="containers.type == 'chart'">
            <div :class="`${containers.customClass}   flex flex-col relative`">
              <f-chart
                :instrument="containers.props.instrument"
                :title="containers.title"
                :defaultZoom="containers.props.defaultZoom"
                :data="containers.props.chart_data"
                :full-data="containers.props.full_data"
              ></f-chart>
            </div>
          </template>

          <!-- For bar chart -->
          <template v-if="containers.type == 'bar_chart'">
            <div :class="`${containers.customClass}   flex flex-col relative`">
              <f-chart-bar
                :colors="containers.props.analysis_chart_data?.colors"
                :data-series="containers.props.analysis_chart_data?.dataSeries"
                :is-money="containers.props.analysis_chart_data?.isMoney"
                :periods="containers.props.analysis_chart_data?.periods"
                :label-suffix="
                  containers.props.analysis_chart_data?.labelSuffix
                "
              />
            </div>
          </template>

          <!-- For line chart -->
          <template v-if="containers.type == 'line_chart'">
            <div :class="`${containers.customClass}   flex flex-col relative`">
              <f-chart-line
                :colors="containers.props.analysis_chart_data?.colors"
                :data-series="containers.props.analysis_chart_data?.dataSeries"
                :is-money="containers.props.analysis_chart_data?.isMoney"
                :periods="containers.props.analysis_chart_data?.periods"
                :label-suffix="
                  containers.props.analysis_chart_data?.labelSuffix
                "
              />
            </div>
          </template>

          <!-- For loaders -->
          <template v-if="containers.type == 'loading'">
            <div
              :class="`${containers.customClass}   flex flex-col space-y-3 relative items-center justify-center`"
            >
              <span v-if="containers.props.info_type != 'error'">
                <svg
                  :class="`animate-spin mr-3 h-5 w-5  text-gray-700`"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>

              <normal-text
                :class="`!text-sm ${
                  containers.props.info_type == 'success'
                    ? '!text-green-600'
                    : ''
                } ${
                  containers.props.info_type == 'error' ? '!text-red-600' : ''
                }    ${
                  containers.props.info_type == 'info' ? '!text-blue-600' : ''
                }  ${
                  containers.props.info_type == 'warning'
                    ? '!text-orange-600'
                    : ''
                }  `"
              >
                {{ containers.props.content }}
              </normal-text>
            </div>
          </template>

          <!-- For info -->
          <template v-if="containers.type == 'info'">
            <div
              :class="`${containers.customClass}   flex flex-col space-y-3 relative items-center justify-center`"
            >
              <div class="w-[70%] flex flex-col">
                <normal-text
                  :class="`!text-sm text-center`"
                  :html-content="containers.props.content"
                >
                </normal-text>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, watch } from "vue";
import { useMeta } from "vue-meta";
import FControlBar from "../components/ControlBar.vue";
import { NormalText } from "../components/Typography";
import FChart from "../components/Chart.vue";
import FChartBar from "../components/Chart/Bar.vue";
import FChartLine from "../components/Chart/Line.vue";
import {
  annualizedResponse,
  controlOptions,
  currentSelectFloatingWindow,
  dayTradeResponse,
  selectedControlKey,
  setContainerContent,
  setDefaultInfoContent,
  setWindowContent,
  windowChartContainers,
  windowFloatingElements,
  winRateResponse,
} from "../composables/window";

export default defineComponent({
  components: {
    FControlBar,
    NormalText,
    FChart,
    FChartBar,
    FChartLine,
  },
  name: "Index",
  setup() {
    useMeta({
      title: "Home",
    });

    watch(currentSelectFloatingWindow, () => {
      windowChartContainers.length = 0;
      setTimeout(() => {
        setContainerContent();
      }, 1000);
    });

    watch(selectedControlKey, () => {
      setDefaultInfoContent();
    });

    const overrideWindowContent = () => {
      setWindowContent();

      if (windowFloatingElements.length) {
        currentSelectFloatingWindow.value = windowFloatingElements[0].key;
      }
    };

    watch(dayTradeResponse, () => {
      overrideWindowContent();
    });

    watch(winRateResponse, () => {
      overrideWindowContent();
    });

    watch(annualizedResponse, () => {
      overrideWindowContent();
    });

    onMounted(() => {
      setDefaultInfoContent();
    });

    return {
      windowFloatingElements,
      windowChartContainers,
      controlOptions,
      selectedControlKey,
      currentSelectFloatingWindow,
    };
  },
});
</script>
