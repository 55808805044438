<template>
  <div
    class="w-full flex flex-col h-full space-y-4 bg-white border-l-[1px] border-gray-400"
    v-if="controlOptions"
  >
    <div class="w-full flex flex-col space-y-4 py-4 px-4">
      <header-text class="text-2xl"> 3Tickers&#174; </header-text>

      <div class="w-full grid grid-cols-2 gap-3">
        <div
          :class="`col-span-1 rounded-[8px] cursor-pointer  ${
            selectedControl == control.key
              ? 'border-purple-400 border-[2px] bg-purple-50 '
              : 'border-gray-200 border-[1px] '
          } px-4 py-4 flex flex-col space-y-2 items-center justify-center`"
          v-for="(control, index) in controlOptions"
          :key="index"
          @click="showControl(control.key)"
        >
          <icon :name="control.icon.name" :customClass="control.icon.size" />

          <normal-text class="text-center !text-xs">
            {{ control.name }}
          </normal-text>
        </div>
      </div>
    </div>

    <div class="h-full sticky bottom-0 flex flex-col space-y-3">
      <div
        class="w-full flex flex-row space-x-2 px-3 py-4 border-b-[1px] border-t-[1px] items-center border-gray-200"
      >
        <i class="la la-cog text-[18px]"></i>
        <normal-text class="!font-semibold !text-sm"> Settings </normal-text>
      </div>
      <!-- Settings -->
      <f-form-wrapper
        :parentRefs="parentRefs"
        ref="formComp"
        class="px-3 grid grid-cols-2 gap-3"
        v-if="
          controlOptions.filter(
            (eachItem) => eachItem.key == selectedControl
          )[0]
        "
      >
        <template
          v-for="(item, index) in controlOptions.filter(
            (eachItem) => eachItem.key == selectedControl
          )[0].settings"
          :key="index"
        >
          <!-- For dates -->
          <template v-if="item.type == 'date'">
            <div :class="`${item.class} flex flex-col `">
              <f-text-field
                :hasTitle="true"
                :placeholder="item.title"
                v-model="item.value"
                :customClass="'!border-gray-200'"
                :type="'date'"
                :rules="item.rules"
                :name="item.title"
                :ref="`main_date_${selectedControl}`"
              >
                <template v-slot:title>
                  {{ item.placeholder }}
                </template>
              </f-text-field>
            </div>
          </template>

          <!-- For period -->
          <template v-if="item.type == 'period'">
            <div :class="`${item.class} flex flex-col space-y-2`">
              <normal-text>
                {{ item.title }}
              </normal-text>
              <div class="w-full grid grid-cols-2 gap-3">
                <div :class="`col-span-1 flex flex-col `">
                  <f-text-field
                    :placeholder="'Enter Period Amount'"
                    v-model="item.back_count"
                    :customClass="'!border-gray-200'"
                    :type="'tel'"
                    :name="'Back Period'"
                    :rules="item.rules"
                    :ref="`back_count_${selectedControl}`"
                  >
                  </f-text-field>
                </div>

                <div :class="`col-span-1 flex flex-col `">
                  <f-select
                    :placeholder="'Select period'"
                    v-model="item.period"
                    :customClass="'!border-gray-200 !border-[1px]'"
                    :paddings="'py-3 px-4'"
                    :options="periodOptions"
                  >
                  </f-select>
                </div>
              </div>
            </div>
          </template>

          <!-- For amount -->
          <template v-if="item.type == 'amount'">
            <div :class="`${item.class} flex flex-col `">
              <f-text-field
                :hasTitle="true"
                :placeholder="item.title"
                v-model="item.value"
                :is-formatted="true"
                :customClass="'!border-gray-200'"
                :name="item.title"
                :type="'tel'"
                :rules="item.rules"
                :ref="`base_capital_${selectedControl}`"
              >
                <template v-slot:title>
                  {{ item.placeholder }}
                </template>
                <template v-slot:inner-prefix>
                  <normal-text> $ </normal-text>
                </template>
              </f-text-field>
            </div>
          </template>
        </template>
      </f-form-wrapper>

      <!-- Run Bottom -->
      <div
        class="absolute bottom-0 left-0 py-4 px-4 bg-white flex flex-col w-full"
      >
        <f-button @click="runAnalysis()"> Run </f-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, watch } from "vue";
import { HeaderText, NormalText } from "./Typography";
import Icon from "./Icon.vue";
import { FTextField, FSelect, FFormWrapper } from "./Form";
import FButton from "./FButton.vue";
import { setWindowData } from "../composables/window";
import { FormRule, SelectOption } from "../types";

export default defineComponent({
  components: {
    HeaderText,
    Icon,
    NormalText,
    FTextField,
    FButton,
    FSelect,
    FFormWrapper,
  },
  props: {
    defaultSetup: {
      type: Object as () => any,
      required: false,
    },
    controlOptions: {
      type: Array as () => {
        name: string;
        key: string;
        icon: {
          name: string;
          size: string;
        };
        settings: {
          type: string;
          value: string;
          placeholder: string;
          title: string;
          class: string;
          period: string;
          back_count: number;
          rules?: FormRule[];
        }[];
      }[],
    },
  },
  name: "FControlBar",
  emits: ["update:modelValue"],
  setup(props, context) {
    const selectedControl = ref("win_rate");

    const formComp = ref();

    const periodOptions = reactive<SelectOption[]>([
      {
        key: "day",
        value: "Days",
      },
      {
        key: "week",
        value: "Weeks",
      },
      {
        key: "month",
        value: "Months",
      },
    ]);

    const runAnalysis = () => {
      const state = formComp.value.validate();
      if (state) {
        setWindowData();
      }
    };

    const showControl = (key: string) => {
      selectedControl.value = "";

      setTimeout(() => {
        selectedControl.value = key;
      }, 500);
    };

    watch(selectedControl, () => {
      context.emit("update:modelValue", selectedControl.value);
    });

    return {
      selectedControl,
      periodOptions,
      formComp,
      runAnalysis,
      showControl,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
