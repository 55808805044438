import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["id", "tabindex"]
const _hoisted_2 = { class: "w-full flex flex-row space-x-1 items-center justify-between" }
const _hoisted_3 = ["value", "placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "w-full py-2 sticky top-0 bg-white flex flex-row items-center justify-between px-2"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "w-full flex flex-row space-x-3 items-center"
}
const _hoisted_7 = {
  key: 1,
  class: "w-[17px] h-[17px] rounded-[3px] border-[1px] border-black"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_normal_text = _resolveComponent("f-normal-text")!
  const _component_f_icon = _resolveComponent("f-icon")!
  const _component_f_text_field = _resolveComponent("f-text-field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`flex flex-col space-y-2 ${_ctx.defaultSize}`)
  }, [
    (_ctx.hasTitle)
      ? (_openBlock(), _createBlock(_component_f_normal_text, { key: 0 }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "title")
          ]),
          _: 3
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(`flex relative flex-row items-center space-x-1 justify-between w-full rounded ${_ctx.customClass} bg-grayBackground ${_ctx.paddings}`),
      id: 'container' + _ctx.tabIndex,
      onFocus: _cache[2] || (_cache[2] = ($event: any) => {
        _ctx.showOption = true;
        _ctx.isFocused = true;
        _ctx.ShowSelectModal = true;
      }),
      onBlur: _cache[3] || (_cache[3] = ($event: any) => {
        _ctx.isFocused = false;
        _ctx.autoComplete ? '' : (_ctx.showOption = false);
      }),
      tabindex: _ctx.tabIndex,
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.ShowSelectModal = true))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("input", {
          ref: "select",
          value: _ctx.withKey ? _ctx.valueData : _ctx.textValue,
          placeholder: _ctx.placeholder,
          disabled: "",
          class: _normalizeClass(` text-black flex-grow bg-transparent placeholder-primaryGray focus input w-full focus:outline-none  `)
        }, null, 8, _hoisted_3),
        _createVNode(_component_f_icon, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => {
            _ctx.showOption = true;
            _ctx.isFocused = true;
          }),
          name: "dropdown",
          "custom-class": "md:h-[7px] h-[6px] cursor-pointer"
        })
      ]),
      (_ctx.showOption)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _withModifiers(($event: any) => (null), ["stop"]),
            class: _normalizeClass(`w-full flex flex-col top-[101%] right-0 xs:hidden sm:hidden bg-white z-50 max-h-[200px] min-w-[120px] overflow-y-auto rounded-md shadow-md absolute`)
          }, [
            (_ctx.autoComplete)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_f_text_field, {
                    placeholder: "Search",
                    modelValue: _ctx.searchValue,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchValue) = $event)),
                    "custom-class": '!w-full !rounded-[8px] !border-neutral_grey_light !border-[1px]'
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResult, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "py-2 px-3 w-full flex flex-row items-center hover:!bg-gray-100 cursor-pointer",
                key: index,
                onClick: _withModifiers(($event: any) => {
            _ctx.selectValue(item);
            _ctx.showOption = false;
          }, ["stop"])
              }, [
                (_ctx.isMultiple)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_ctx.itemIsSelected(item.key))
                        ? (_openBlock(), _createBlock(_component_f_icon, {
                            key: 0,
                            name: 
                  _ctx.itemIsSelected(item.key) ? 'checkbox-active' : 'checkbox'
                ,
                            "custom-class": "h-[16px]"
                          }, null, 8, ["name"]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_7)),
                      _createVNode(_component_f_normal_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.value), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  : (_openBlock(), _createBlock(_component_f_normal_text, { key: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.value), 1)
                      ]),
                      _: 2
                    }, 1024))
              ], 8, _hoisted_5))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 42, _hoisted_1)
  ], 2))
}