<template>
  <span
    :class="`
  ${customClass} ${color}
  ${size == 'base' ? '!text-xs' : ''}
  ${size == 'small' ? ' text-xs' : ''}`"
  >
    <slot />
    <span
      v-if="htmlContent"
      v-html="htmlContent"
      :class="`prose-sm prose !text-shop_dark_300 prose-ul:!mb-0 ${htmlStyle}`"
    ></span>
  </span>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    size: {
      type: String,
      default: "base",
    },
    color: {
      type: String,
      default: "text-[#2D3142]",
    },
    customClass: {
      type: String,
      default: "",
    },
    htmlContent: {
      type: String,
      default: "",
    },
    htmlStyle: {
      type: String,
      default: "",
    },
  },
  name: "NormalText",
});
</script>
