import { AxiosResponse } from 'axios'
import { ReadOnlyApiService } from './common/ReadOnlyService'
import { ChartData, NewCalendar } from '@/types/Datamine'

export class DataSourceApi extends ReadOnlyApiService {
  constructor() {
    // this is equivalent to your base_url/sample
    super('datasource')
  }

  public async marketData(data: {
    date: string
    arithm: 'add' | 'substract'
    daysCount?: number | undefined
    ticker: string
    timeframe:
      | '1mo'
      | '1wk'
      | '1d'
      | '4h'
      | '1h'
      | '2h'
      | '30m'
      | '15m'
      | '5m'
      | '1m'
    use_firsttick: boolean
  }): Promise<AxiosResponse<ChartData[]>> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        this.getUrl() + '/market-data',
        data,
      )

      return response
    } catch (err) {
      this.handleErrors(err)
      // you can handle request specific error here
      throw err
    }
  }

  public async weekCalendarNew(data: {
    weekDate: string
  }): Promise<
    AxiosResponse<
      {
        date: string
        news: NewCalendar[]
      }[]
    >
  > {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        this.getUrl() + '/week-calendar-news',
        data,
      )

      return response
    } catch (err) {
      this.handleErrors(err)
      // you can handle request specific error here
      throw err
    }
  }
}
