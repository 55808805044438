<template>
  <VueApexCharts
    type="area"
    height="100%"
    :options="chartConfig"
    :series="dataSeries"
  />
</template>
<script lang="ts">
import { getAreaChartSplineConfig } from "../../composables/chart";
import { defineComponent } from "vue";
import VueApexCharts from "vue3-apexcharts";

export default defineComponent({
  components: {
    VueApexCharts,
  },
  props: {
    colors: {
      type: Array as () => string[],
      default: () => {
        return ["#9754BF", "#55B982", "#D75D5D"];
      },
    },
    labelSuffix: {
      type: String,
      default: "",
    },
    periods: {
      type: Array as () => string[],
      default: () => [],
    },
    isMoney: {
      type: Boolean,
      default: false,
    },
    dataSeries: {
      type: Array as () => {
        name: string;
        data: number[];
      }[],
    },
  },
  name: "FChartLine",
  setup(props) {
    const chartConfig = getAreaChartSplineConfig(
      {
        themeBorderColor: "#F3F3F3",
        themeDisabledTextColor: "#797979",
        themeSecondaryTextColor: "#797979",
      },
      props.colors,
      props.isMoney,
      props.periods
    );

    return {
      chartConfig,
    };
  },
});
</script>
