import { ref } from 'vue'
import { makeid } from '.'
import {
  annualizedResponse,
  currentLoadingRequestID,
  dayTradeResponse,
  windowChartContainers,
  winRateResponse,
} from './window'

const socket = ref<WebSocket>()
const socketIsOpen = ref(false)

const connectToWebSocket = () => {
  return new Promise((resolve, reject) => {
    let browserSession = localStorage.getItem('BrowserSession')

    if (!browserSession) {
      browserSession = makeid(25)
      localStorage.setItem('BrowserSession', browserSession)
    }

    const baseUrl = window.location.host

    socket.value = new WebSocket(`wss://${baseUrl}/socket`, browserSession)

    // On message entered
    socket.value?.addEventListener('message', (event) => {
      const eventData = JSON.parse(event.data)

      if (eventData.requestId == currentLoadingRequestID.value) {
        windowChartContainers[0].props = {
          content: eventData.content,
          info_type: eventData.type,
        }
      }

      if (eventData.type == 'success' && !eventData.extra_data?.key) {
        dayTradeResponse.value = eventData.extra_data
      }

      if (
        eventData.type == 'success' &&
        eventData.extra_data?.key == 'win_rate'
      ) {
        winRateResponse.value = eventData.extra_data
      }

      if (
        eventData.type == 'success' &&
        eventData.extra_data?.key == 'annualized'
      ) {
        annualizedResponse.value = eventData.extra_data
      }
    })

    // socket opened
    socket.value.addEventListener('open', (event) => {
      console.log('opened', event.target)
      resolve('Connected')
      socketIsOpen.value = true
    })

    // socket closed
    socket.value.addEventListener('close', (event) => {
      console.log('closed', event.target)
      socketIsOpen.value = false
    })

    // error handler
    socket.value.addEventListener('error', (event) => {
      console.log('error', event.target)
      reject('Unable to connect')
    })
  })
}

export { socketIsOpen, connectToWebSocket }
