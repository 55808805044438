import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueApexCharts = _resolveComponent("VueApexCharts")!

  return (_openBlock(), _createBlock(_component_VueApexCharts, {
    type: "area",
    height: "100%",
    options: _ctx.chartConfig,
    series: _ctx.dataSeries
  }, null, 8, ["options", "series"]))
}