import { $api } from '@/services/api'
import {
  AnalysisChartData,
  ChartComponentData,
  ChartLabel,
  ChartSection,
  DayAnalysisResponse,
  FloatingElement,
  FormRule,
  Indicator,
} from '@/types'
import { capitalize } from 'lodash'
import moment from 'moment'
import { reactive, ref } from 'vue'
import { convertToMoney, makeid } from '.'
import { TradeAnalysisResponse } from '@/types/DayTrade'
import { connectToWebSocket, socketIsOpen } from './socket'

const currentLoadingRequestID = ref('')

const selectedControlKey = ref('win_rate')

const dayTradeResponse = ref<DayAnalysisResponse>()

const winRateResponse = ref<TradeAnalysisResponse>()

const annualizedResponse = ref<TradeAnalysisResponse>()

const currentSelectFloatingWindow = ref('')

const windowFloatingElements = reactive<FloatingElement[]>([])

const windowChartContainers = reactive<
  {
    customClass: string
    props: {
      instrument?: string
      defaultZoom?: number
      endDate?: string
      content?: string
      info_type?: string
      timeframe?:
        | '1m'
        | '5m'
        | '15m'
        | '30m'
        | '1h'
        | '2h'
        | '4h'
        | '1d'
        | '1wk'
        | '1mo'
      indicators?: Indicator[]
      labels?: ChartLabel[]
      chart_data?: ChartSection[]
      full_data?: ChartComponentData[]
      analysis_chart_data?: AnalysisChartData
    }
    type: string
    title: string
  }[]
>([])

const controlOptions = reactive<
  {
    name: string
    key: string
    icon: {
      name: string
      size: string
    }
    settings: {
      type: string
      value: string
      placeholder: string
      title: string
      class: string
      endWeek?: string
      period: any
      back_count: number
      rules?: FormRule[]
    }[]
    request_id: string
    loading: boolean
  }[]
>([
  {
    name: 'Win Rate',
    key: 'win_rate',
    icon: {
      name: 'win-rate',
      size: 'h-[34px]',
    },
    settings: [
      {
        type: 'date',
        value: '2023-12-16',
        placeholder: 'Choose End Date',
        title: 'End Date',
        class: 'col-span-2',
        endWeek: '2023-12-16',
        back_count: 0,
        period: '',
        rules: [
          {
            type: 'isGreaterThanOrSameDate',
            value: '2023-01-09',
            errorMessage: '',
          },
          {
            type: 'isLessThanOrSameDate',
            value: moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD'),
            errorMessage: '',
          },
        ],
      },
      {
        type: 'period',
        value: '',
        period: 'month',
        back_count: 12,
        placeholder: '',
        title: 'Analysis Period',
        class: 'col-span-2',
        endWeek: '',
        rules: [
          {
            type: 'isGreaterThan',
            value: 1,
            errorMessage: '',
          },
        ],
      },
      {
        type: 'amount',
        value: '100000',
        period: '',
        back_count: 0,
        placeholder: 'Enter Base Capital',
        title: 'Base Capital',
        class: 'col-span-2',
        endWeek: '',
        rules: [
          {
            type: 'isGreaterThan',
            value: 4999,
            errorMessage: '',
          },
        ],
      },
    ],
    request_id: '',
    loading: false,
  },
  {
    name: 'Annulaized Analysis',
    key: 'annualized_volatility',
    icon: {
      name: 'annualized-volatility',
      size: 'h-[30px]',
    },
    settings: [
      {
        type: 'date',
        value: '2023-12-16',
        placeholder: 'Choose End Date',
        title: 'End Date',
        class: 'col-span-2',
        endWeek: '2023-12-16',
        back_count: 0,
        period: '',
        rules: [
          {
            type: 'isGreaterThanOrSameDate',
            value: '2023-01-09',
            errorMessage: '',
          },
          {
            type: 'isLessThanOrSameDate',
            value: moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD'),
            errorMessage: '',
          },
        ],
      },
      {
        type: 'period',
        value: '',
        period: 'month',
        back_count: 12,
        placeholder: '',
        title: 'Analysis Period',
        class: 'col-span-2',
        endWeek: '',
        rules: [
          {
            type: 'isGreaterThan',
            value: 1,
            errorMessage: '',
          },
        ],
      },
      {
        type: 'amount',
        value: '100000',
        period: '',
        back_count: 0,
        placeholder: 'Enter Base Capital',
        title: 'Base Capital',
        class: 'col-span-2',
        endWeek: '',
        rules: [
          {
            type: 'isGreaterThan',
            value: 4999,
            errorMessage: '',
          },
        ],
      },
    ],
    request_id: '',
    loading: false,
  },
  {
    name: 'Day Trade Analysis',
    key: 'day_trade_analysis',
    icon: {
      name: 'day-trade-analysis',
      size: 'h-[30px]',
    },
    settings: [
      {
        type: 'date',
        value: '2024-02-21',
        placeholder: 'Choose Market Day',
        title: 'Market Day',
        class: 'col-span-2',
        endWeek: '2024-02-18',
        back_count: 0,
        period: '',
        rules: [
          {
            type: 'isGreaterThanOrSameDate',
            value: '2023-01-09',
            errorMessage: '',
          },
          {
            type: 'isLessThanOrSameDate',
            value: moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD'),
            errorMessage: '',
          },
        ],
      },
    ],
    request_id: '',
    loading: false,
  },
])

const setWindowContent = () => {
  windowChartContainers.length = 0
  windowFloatingElements.length = 0

  const uniqueKey = makeid(16)

  if (selectedControlKey.value == 'day_trade_analysis') {
    // Set floating elements

    if (dayTradeResponse.value) {
      // Add All Trade Orders
      const dayTrades = dayTradeResponse.value.trade_orders?.target_day_data

      if (dayTrades) {
        const maxTradeCount = Math.max(
          ...dayTrades.map((item) => item.currentTrades.length),
        )

        for (let index = 0; index < maxTradeCount; index++) {
          // Floating element for order DOL

          const instrumentTradeDOL = dayTrades[0].currentTrades[index]

          const tradeDOL = instrumentTradeDOL.summary.reaction_dol

          if (tradeDOL) {
            const floatElementDOL: FloatingElement = {
              key: `dol_trade_order_${index + 1}_${uniqueKey}`,
              title: `DOL Trade Order #${index + 1} - ${
                tradeDOL.reaction_details.reversal_reason
              } - <span class="${
                tradeDOL.reaction_details.next_day_trade_action == 'buy'
                  ? 'text-green-500'
                  : 'text-red-500'
              } pl-2">${capitalize(
                tradeDOL.reaction_details.next_day_trade_action,
              )}</span>`,
              type: 'charts',
            }

            const sections: ChartSection[] = []

            // For 1wk
            if (
              tradeDOL.reaction_details.pd_array_at.pd_array.timeframe ==
                '1wk' ||
              tradeDOL.reaction_details?.next_pd_array_in_view?.timeframe ==
                '1wk'
            ) {
              let finalDate = ''

              const allLabels: ChartLabel[] = []

              if (
                tradeDOL.reaction_details.pd_array_at.pd_array.timeframe ==
                '1wk'
              ) {
                finalDate =
                  tradeDOL.reaction_details.pd_array_at.pd_array.date_region.end

                const reactionPDArray =
                  tradeDOL.reaction_details.pd_array_at.pd_array

                allLabels.push({
                  candles: [
                    reactionPDArray.candles.start.id,
                    reactionPDArray.candles.middle?.id || 0,
                    reactionPDArray.candles.end.id,
                  ],
                  color: '#14BDEB',
                  timeframe: '1wk',
                  title: 'Reaction DOL (PDArray)',
                })
              }

              if (
                tradeDOL.reaction_details.next_pd_array_in_view?.timeframe ==
                '1wk'
              ) {
                if (
                  moment(
                    tradeDOL.reaction_details.next_pd_array_in_view.date_region
                      .end,
                  ).isAfter(moment(finalDate)) ||
                  !finalDate
                ) {
                  finalDate =
                    tradeDOL.reaction_details.next_pd_array_in_view.date_region
                      .end
                }

                finalDate =
                  tradeDOL.reaction_details.next_pd_array_in_view.date_region
                    .end

                const nextDOLPDArray =
                  tradeDOL.reaction_details.next_pd_array_in_view

                allLabels.push({
                  candles: [
                    nextDOLPDArray.candles.start.id,
                    nextDOLPDArray.candles.middle?.id || 0,
                    nextDOLPDArray.candles.end.id,
                  ],
                  color: '#14BDEB',
                  timeframe: '1wk',
                  title: 'Next Reaction (DOL) PDArray',
                })
              }

              if (allLabels.length) {
                sections.push({
                  timeframe: '1wk',
                  endDate: finalDate,
                  labels: allLabels,
                })
              }
            }

            // For 1d
            if (
              tradeDOL.reaction_details.pd_array_at.pd_array.timeframe ==
                '1d' ||
              tradeDOL.reaction_details?.next_pd_array_in_view?.timeframe ==
                '1d' ||
              tradeDOL.reaction_details.current_day_price_action
            ) {
              let finalDate = ''

              const allLabels: ChartLabel[] = []

              if (
                tradeDOL.reaction_details.pd_array_at.pd_array.timeframe == '1d'
              ) {
                finalDate =
                  tradeDOL.reaction_details.pd_array_at.pd_array.date_region.end

                const reactionPDArray =
                  tradeDOL.reaction_details.pd_array_at.pd_array

                allLabels.push({
                  candles: [
                    reactionPDArray.candles.start.id,
                    reactionPDArray.candles.middle?.id || 0,
                    reactionPDArray.candles.end.id,
                  ],
                  color: '#14BDEB',
                  timeframe: '1d',
                  title: 'Reaction DOL (PDArray)',
                })
              }

              if (
                tradeDOL.reaction_details.next_pd_array_in_view?.timeframe ==
                '1d'
              ) {
                if (
                  moment(
                    tradeDOL.reaction_details.next_pd_array_in_view.date_region
                      .end,
                  ).isAfter(moment(finalDate)) ||
                  !finalDate
                ) {
                  finalDate =
                    tradeDOL.reaction_details.next_pd_array_in_view.date_region
                      .end
                }

                finalDate =
                  tradeDOL.reaction_details.next_pd_array_in_view.date_region
                    .end

                const nextDOLPDArray =
                  tradeDOL.reaction_details.next_pd_array_in_view

                allLabels.push({
                  candles: [
                    nextDOLPDArray.candles.start.id,
                    nextDOLPDArray.candles.middle?.id || 0,
                    nextDOLPDArray.candles.end.id,
                  ],
                  color: '#14BDEB',
                  timeframe: '1d',
                  title: 'Next Reaction (DOL) PDArray',
                })
              }

              if (tradeDOL.reaction_details.current_day_price_action) {
                if (
                  moment(
                    tradeDOL.reaction_details.current_day_price_action.Date,
                  ).isAfter(moment(finalDate)) ||
                  !finalDate
                ) {
                  finalDate =
                    tradeDOL.reaction_details.current_day_price_action.Date
                }

                allLabels.push({
                  candles: [
                    tradeDOL.reaction_details.current_day_price_action.id,
                  ],
                  color: '#AABD8C',
                  timeframe: '1d',
                  title: 'Reacting Price Action',
                })
              }

              if (allLabels.length) {
                sections.push({
                  timeframe: '1d',
                  endDate: finalDate,
                  labels: allLabels,
                })
              }
            }

            // For 1hr
            if (
              tradeDOL.reaction_details.reversal_confirmation_structure ||
              tradeDOL.reaction_details.unvisited_reversal_structure
            ) {
              let finalDate = ''

              const allLabels: ChartLabel[] = []

              if (tradeDOL.reaction_details.reversal_confirmation_structure) {
                finalDate =
                  tradeDOL.reaction_details.reversal_confirmation_structure
                    .date_region.end

                const PDArray =
                  tradeDOL.reaction_details.reversal_confirmation_structure

                allLabels.push({
                  candles: [
                    PDArray.candles.start.id,
                    PDArray.candles.middle?.id || 0,
                    PDArray.candles.end.id,
                  ],
                  color: '#932F6D',
                  timeframe: '1h',
                  title: 'Reversal Confirmation',
                })
              }

              if (tradeDOL.reaction_details.unvisited_reversal_structure) {
                if (
                  moment(
                    tradeDOL.reaction_details.unvisited_reversal_structure
                      .date_region.end,
                  ).isAfter(moment(finalDate)) ||
                  !finalDate
                ) {
                  finalDate =
                    tradeDOL.reaction_details.unvisited_reversal_structure
                      .date_region.end
                }

                const PDArray =
                  tradeDOL.reaction_details.unvisited_reversal_structure

                allLabels.push({
                  candles: [
                    PDArray.candles.start.id,
                    PDArray.candles.middle?.id || 0,
                    PDArray.candles.end.id,
                  ],
                  color: '#41658A',
                  timeframe: '1h',
                  title: 'Unvisted Reversal Structure',
                })
              }

              if (allLabels.length) {
                sections.push({
                  timeframe: '1h',
                  endDate: finalDate,
                  labels: allLabels,
                })
              }
            }

            floatElementDOL.chart_data = [
              {
                instrument: tradeDOL.reaction_details.ticker,
                sections,
              },
            ]

            windowFloatingElements.push(floatElementDOL)
          }

          const floatElement: FloatingElement = {
            key: `trade_order_${index + 1}_${uniqueKey}`,
            title: `Trade Order #${index + 1}`,
            type: 'charts',
          }

          dayTrades.forEach((trade) => {
            const instrumentTrade = trade.currentTrades[index]

            if (instrumentTrade) {
              const sections: ChartSection[] = []

              // For 1m

              if (
                instrumentTrade.summary.trade_entry_price_action ||
                instrumentTrade.summary.price_reached_take_profit ||
                instrumentTrade.summary.price_passed_stop_loss
              ) {
                let finalDate = ''

                const allLabels: ChartLabel[] = []

                if (instrumentTrade.summary.trade_entry_price_action) {
                  finalDate =
                    instrumentTrade.summary.trade_entry_price_action.Date
                  allLabels.push({
                    candles: [
                      instrumentTrade.summary.trade_entry_price_action.id,
                    ],
                    color: '#514B23',
                    timeframe: '1m',
                    title: 'Trade Entry',
                  })
                }

                if (instrumentTrade.summary.price_reached_take_profit) {
                  if (
                    moment(
                      instrumentTrade.summary.price_reached_take_profit
                        ?.price_action?.Date,
                    ).isAfter(moment(finalDate)) ||
                    !finalDate
                  ) {
                    finalDate =
                      instrumentTrade.summary.price_reached_take_profit
                        ?.price_action?.Date || ''
                  }

                  allLabels.push({
                    candles: [
                      instrumentTrade.summary.price_reached_take_profit
                        .price_action?.id || 0,
                    ],
                    color: '#C6A15B',
                    timeframe: '1m',
                    title: 'Trade Take Profit',
                  })
                }

                if (instrumentTrade.summary.price_passed_stop_loss) {
                  if (
                    moment(
                      instrumentTrade.summary.price_passed_stop_loss
                        ?.price_action?.Date,
                    ).isAfter(moment(finalDate)) ||
                    !finalDate
                  ) {
                    finalDate =
                      instrumentTrade.summary.price_passed_stop_loss
                        ?.price_action?.Date || ''
                  }

                  allLabels.push({
                    candles: [
                      instrumentTrade.summary.price_passed_stop_loss
                        .price_action?.id || 0,
                    ],
                    color: '#7B1E7A',
                    timeframe: '1m',
                    title: 'Trade Stop Loss',
                  })
                }

                if (allLabels.length) {
                  sections.push({
                    timeframe: '1m',
                    endDate: finalDate,
                    labels: allLabels,
                  })
                }
              }

              // For 15m

              if (
                instrumentTrade.summary.trade_pd_array ||
                instrumentTrade.summary.take_profit_pd_array?.timeframe == '15m'
              ) {
                let finalDate = ''

                const allLabels: ChartLabel[] = []

                if (instrumentTrade.summary.trade_pd_array) {
                  finalDate =
                    instrumentTrade.summary.trade_pd_array.date_region.end
                  allLabels.push({
                    candles: [
                      instrumentTrade.summary.trade_pd_array.candles.start.id,
                      instrumentTrade.summary.trade_pd_array.candles.middle
                        ?.id || 0,
                      instrumentTrade.summary.trade_pd_array.candles.end.id,
                    ],
                    color: '#E3B23C',
                    timeframe: '15m',
                    title: 'Entry OB',
                  })
                }

                if (
                  instrumentTrade.summary.take_profit_pd_array?.timeframe ==
                  '15m'
                ) {
                  if (
                    moment(
                      instrumentTrade.summary.take_profit_pd_array.date_region
                        .end,
                    ).isAfter(moment(finalDate)) ||
                    !finalDate
                  ) {
                    finalDate =
                      instrumentTrade.summary.take_profit_pd_array.date_region
                        .end || ''
                  }

                  allLabels.push({
                    candles: [
                      instrumentTrade.summary.take_profit_pd_array.candles.start
                        .id,
                      instrumentTrade.summary.take_profit_pd_array.candles
                        .middle?.id || 0,
                      instrumentTrade.summary.take_profit_pd_array.candles.end
                        .id,
                    ],
                    color: '#2F131E',
                    timeframe: '15m',
                    title: 'Take Profit PDArray',
                  })
                }

                if (allLabels.length) {
                  sections.push({
                    timeframe: '15m',
                    endDate: finalDate,
                    labels: allLabels,
                  })
                }
              }

              // 30m
              if (
                instrumentTrade.summary.take_profit_pd_array?.timeframe == '30m'
              ) {
                let finalDate = ''

                const allLabels: ChartLabel[] = []

                if (
                  instrumentTrade.summary.take_profit_pd_array?.timeframe ==
                  '30m'
                ) {
                  finalDate =
                    instrumentTrade.summary.take_profit_pd_array.date_region.end

                  allLabels.push({
                    candles: [
                      instrumentTrade.summary.take_profit_pd_array.candles.start
                        .id,
                      instrumentTrade.summary.take_profit_pd_array.candles
                        .middle?.id || 0,
                      instrumentTrade.summary.take_profit_pd_array.candles.end
                        .id,
                    ],
                    color: '#2F131E',
                    timeframe: '30m',
                    title: 'Take Profit PDArray',
                  })
                }

                if (allLabels.length) {
                  sections.push({
                    timeframe: '30m',
                    endDate: finalDate,
                    labels: allLabels,
                  })
                }
              }

              // 1hr

              if (
                instrumentTrade.summary.take_profit_pd_array?.timeframe == '1h'
              ) {
                let finalDate = ''

                const allLabels: ChartLabel[] = []

                if (
                  instrumentTrade.summary.take_profit_pd_array?.timeframe ==
                  '1h'
                ) {
                  finalDate =
                    instrumentTrade.summary.take_profit_pd_array.date_region.end

                  allLabels.push({
                    candles: [
                      instrumentTrade.summary.take_profit_pd_array.candles.start
                        .id,
                      instrumentTrade.summary.take_profit_pd_array.candles
                        .middle?.id || 0,
                      instrumentTrade.summary.take_profit_pd_array.candles.end
                        .id,
                    ],
                    color: '#2F131E',
                    timeframe: '1h',
                    title: 'Take Profit PDArray',
                  })
                }

                if (allLabels.length) {
                  sections.push({
                    timeframe: '1h',
                    endDate: finalDate,
                    labels: allLabels,
                  })
                }
              }

              // For 2hrs
              if (
                instrumentTrade.summary.two_hour_retracement?.instrument ==
                instrumentTrade.summary.instrument
              ) {
                let finalDate = ''

                const allLabels: ChartLabel[] = []

                finalDate =
                  instrumentTrade.summary.two_hour_retracement
                    .two_hours_pdarray_retracement.reaction_price_action.Date

                const retracementPDArray =
                  instrumentTrade.summary.two_hour_retracement
                    .two_hours_pdarray_retracement.pd_array

                // For retracement PDArray
                allLabels.push({
                  candles: [
                    retracementPDArray.candles.start.id,
                    retracementPDArray.candles.middle?.id || 0,
                    retracementPDArray.candles.end.id,
                  ],
                  color: '#2C6E49',
                  timeframe: '2h',
                  title: '2hr Retracement PDArray',
                })

                // For reaction priceaction
                allLabels.push({
                  candles: [
                    instrumentTrade.summary.two_hour_retracement
                      .two_hours_pdarray_retracement.reaction_price_action.id,
                  ],
                  color: '#F40076',
                  timeframe: '2h',
                  title: '2hr Retracement Reaction',
                })

                if (allLabels.length) {
                  sections.push({
                    timeframe: '2h',
                    endDate: finalDate,
                    labels: allLabels,
                  })
                }
              }

              // For 4hr
              if (
                instrumentTrade.summary.tp_swing ||
                instrumentTrade.summary.take_profit_pd_array?.timeframe == '4h'
              ) {
                let finalDate = ''

                const allLabels: ChartLabel[] = []

                if (instrumentTrade.summary.tp_swing) {
                  finalDate = instrumentTrade.summary.tp_swing.date_region.end

                  allLabels.push({
                    candles: [
                      instrumentTrade.summary.tp_swing.candles.start.id,
                      instrumentTrade.summary.tp_swing.candles.middle?.id || 0,
                      instrumentTrade.summary.tp_swing.candles.end.id,
                    ],
                    color: '#820263',
                    timeframe: '4h',
                    title: 'Take Profit Swing (boundary)',
                  })

                  if (
                    instrumentTrade.summary.take_profit_pd_array?.timeframe ==
                    '4h'
                  ) {
                    if (
                      moment(
                        instrumentTrade.summary.take_profit_pd_array.date_region
                          .end,
                      ).isAfter(moment(finalDate)) ||
                      !finalDate
                    ) {
                      finalDate =
                        instrumentTrade.summary.take_profit_pd_array.date_region
                          .end || ''
                    }

                    allLabels.push({
                      candles: [
                        instrumentTrade.summary.take_profit_pd_array.candles
                          .start.id,
                        instrumentTrade.summary.take_profit_pd_array.candles
                          .middle?.id || 0,
                        instrumentTrade.summary.take_profit_pd_array.candles.end
                          .id,
                      ],
                      color: '#2F131E',
                      timeframe: '4h',
                      title: 'Take Profit PDArray',
                    })

                    if (allLabels.length) {
                      sections.push({
                        timeframe: '4h',
                        endDate: finalDate,
                        labels: allLabels,
                      })
                    }
                  }

                  // For 1d

                  if (
                    instrumentTrade.summary.dol?.timeframe == '1d' ||
                    instrumentTrade.summary.opposite_next_dol_in_view
                      ?.timeframe == '1d'
                  ) {
                    let finalDate = ''

                    const allLabels: ChartLabel[] = []

                    if (instrumentTrade.summary.dol?.timeframe == '1d') {
                      finalDate = instrumentTrade.summary.dol?.date_region.end

                      allLabels.push({
                        candles: [
                          instrumentTrade.summary.dol.candles.start.id,
                          instrumentTrade.summary.dol.candles.middle?.id || 0,
                          instrumentTrade.summary.dol.candles.end.id,
                        ],
                        color: '#574AE2',
                        timeframe: '1d',
                        title: 'DOL in view',
                      })
                    }

                    if (
                      instrumentTrade.summary.opposite_next_dol_in_view
                        ?.timeframe == '1d'
                    ) {
                      if (
                        moment(
                          instrumentTrade.summary.opposite_next_dol_in_view
                            ?.date_region.end,
                        ).isAfter(moment(finalDate)) ||
                        !finalDate
                      ) {
                        finalDate =
                          instrumentTrade.summary.opposite_next_dol_in_view
                            ?.date_region.end
                      }

                      allLabels.push({
                        candles: [
                          instrumentTrade.summary.opposite_next_dol_in_view
                            .candles.start.id,
                          instrumentTrade.summary.opposite_next_dol_in_view
                            .candles.middle?.id || 0,
                          instrumentTrade.summary.opposite_next_dol_in_view
                            .candles.end.id,
                        ],
                        color: '#14BDEB',
                        timeframe: '1d',
                        title: 'Oppostie DOL in view',
                      })
                    }

                    if (allLabels.length) {
                      sections.push({
                        timeframe: '1d',
                        endDate: finalDate,
                        labels: allLabels,
                      })
                    }
                  }

                  // For 1wk

                  if (
                    instrumentTrade.summary.dol?.timeframe == '1wk' ||
                    instrumentTrade.summary.opposite_next_dol_in_view
                      ?.timeframe == '1wk'
                  ) {
                    let finalDate = ''

                    const allLabels: ChartLabel[] = []

                    if (instrumentTrade.summary.dol?.timeframe == '1wk') {
                      finalDate = instrumentTrade.summary.dol?.date_region.end

                      allLabels.push({
                        candles: [
                          instrumentTrade.summary.dol.candles.start.id,
                          instrumentTrade.summary.dol.candles.middle?.id || 0,
                          instrumentTrade.summary.dol.candles.end.id,
                        ],
                        color: '#574AE2',
                        timeframe: '1wk',
                        title: 'DOL in view',
                      })
                    }

                    if (
                      instrumentTrade.summary.opposite_next_dol_in_view
                        ?.timeframe == '1wk'
                    ) {
                      if (
                        moment(
                          instrumentTrade.summary.opposite_next_dol_in_view
                            ?.date_region.end,
                        ).isAfter(moment(finalDate)) ||
                        !finalDate
                      ) {
                        finalDate =
                          instrumentTrade.summary.opposite_next_dol_in_view
                            ?.date_region.end
                      }

                      allLabels.push({
                        candles: [
                          instrumentTrade.summary.opposite_next_dol_in_view
                            .candles.start.id,
                          instrumentTrade.summary.opposite_next_dol_in_view
                            .candles.middle?.id || 0,
                          instrumentTrade.summary.opposite_next_dol_in_view
                            .candles.end.id,
                        ],
                        color: '#14BDEB',
                        timeframe: '1wk',
                        title: 'Oppostie DOL in view',
                      })
                    }

                    if (allLabels.length) {
                      sections.push({
                        timeframe: '1wk',
                        endDate: finalDate,
                        labels: allLabels,
                      })
                    }
                  }
                }
              }

              if (floatElement.chart_data == undefined) {
                floatElement.chart_data = [
                  {
                    instrument: instrumentTrade.summary.instrument,
                    sections: sections,
                  },
                ]
              } else {
                floatElement.chart_data?.push({
                  instrument: instrumentTrade.summary.instrument,
                  sections: sections,
                })
              }
            }
          })

          windowFloatingElements.push(floatElement)
        }
      }

      // Add AMD (Accumulation , manipulation and disstribution)

      const AMD = dayTradeResponse.value.amd

      if (AMD) {
        const floatElement: FloatingElement = {
          key: `amd_${uniqueKey}`,
          title: `Trade AMD`,
          type: 'charts',
        }

        const sections: ChartSection[] = []

        let finalDate = ''

        const allLabels: ChartLabel[] = []
        // Accumulation
        if (AMD.accumulation) {
          finalDate = AMD.accumulation?.date_region.end

          allLabels.push({
            candles: [
              AMD.accumulation.candles.start.id,
              AMD.accumulation.candles.middle?.id || 0,
              AMD.accumulation.candles.end.id,
            ],
            color: '#574AE2',
            timeframe: AMD.accumulation.timeframe,
            title: 'Accumulation',
          })
        }

        // Manipulator
        if (AMD.manipulator) {
          finalDate = AMD.manipulator.Date

          allLabels.push({
            candles: [AMD.manipulator.id],
            color: '#A4AF69',
            timeframe: '4h',
            title: 'Manipulator',
          })
        }

        if (allLabels.length) {
          sections.push({
            timeframe: '4h',
            endDate: finalDate,
            labels: allLabels,
          })
        }

        floatElement.chart_data = [
          {
            instrument: AMD.instrument || '',
            sections: sections,
          },
        ]

        windowFloatingElements.push(floatElement)
      }

      // Market News

      const marketNews = dayTradeResponse.value.market_news

      if (marketNews) {
        const floatElement: FloatingElement = {
          key: `market_news_${uniqueKey}`,
          title: `Market News`,
          type: 'modal',
          modal_data: marketNews[0].news.map((item) => {
            return {
              content: `${item.name} - <span class="${
                item.has_report ? 'text-red-500' : ''
              } pl-2">${item.time}</span>`,
              is_important: item.has_report,
            }
          }),
        }

        windowFloatingElements.push(floatElement)
      }
    }
  }

  // For win rate

  if (selectedControlKey.value == 'win_rate') {
    if (winRateResponse.value) {
      // Profit and loss
      let totalProits = 0
      let totalLoss = 0
      let totalReturn = 0
      let totalWinRates = 0

      winRateResponse.value.analysis.forEach((analysis) => {
        totalProits += analysis.profit
        totalLoss += analysis.loss
        totalWinRates += analysis.win_rate
        totalReturn += analysis.return
      })

      const averageWinRate =
        totalWinRates / winRateResponse.value.analysis.length

      const averageReturns =
        (totalReturn /
          parseFloat(controlOptions[0].settings[2].value.replace(/,/g, ''))) *
        100

      const accumulatedReturns: number[] = []

      let currentReturn = 0

      winRateResponse.value.analysis
        .map((item) => item.return)
        .forEach((returnAmount) => {
          currentReturn += returnAmount
          accumulatedReturns.push(currentReturn)
        })

      const floatElementProfitLoss: FloatingElement = {
        key: `profit_and_loss_${uniqueKey}`,
        title: `Profit (${convertToMoney(
          totalProits,
          true,
          'usd',
        )}), Loss (${convertToMoney(
          totalLoss,
          true,
          'usd',
        )}) and Return (${convertToMoney(totalReturn, true, 'usd')})`,
        type: 'analysis_bar',
        analysis_data: {
          colors: ['#2AA763', '#CD3535', '#05A8AA'],
          periods: winRateResponse.value.periods,
          isMoney: true,
          labelSuffix: '',
          dataSeries: [
            {
              name: 'Profit',
              type: 'column',
              data: winRateResponse.value.analysis.map((item) => item.profit),
            },
            {
              name: 'Loss',
              type: 'column',
              data: winRateResponse.value.analysis.map((item) => item.loss),
            },
            {
              name: 'Return',
              type: 'column',
              data: winRateResponse.value.analysis.map((item) => item.return),
            },
          ],
        },
      }

      windowFloatingElements.push(floatElementProfitLoss)

      // Accumulate Returns
      const floatElementAccReturn: FloatingElement = {
        key: `accumulated_return_${uniqueKey}`,
        title: `Accumulated Return (Avg = ${convertToMoney(
          averageReturns,
          true,
          '',
        )}%)`,
        type: 'analysis_line',
        analysis_data: {
          colors: ['#2AA763'],
          periods: winRateResponse.value.periods,
          isMoney: true,
          labelSuffix: '',
          dataSeries: [
            {
              name: 'Accumulated Returns',
              data: accumulatedReturns,
            },
          ],
        },
      }

      windowFloatingElements.push(floatElementAccReturn)

      // Win rate
      const floatElementWinRate: FloatingElement = {
        key: `win_rate_${uniqueKey}`,
        title: `Win Rate (Avg = ${convertToMoney(averageWinRate, true, '')}%)`,
        type: 'analysis_bar',
        analysis_data: {
          colors: ['#9D4EDD'],
          periods: winRateResponse.value.periods,
          isMoney: false,
          labelSuffix: '%',
          dataSeries: [
            {
              name: 'Win Rate',
              data: winRateResponse.value.analysis.map((item) => item.win_rate),
            },
          ],
        },
      }

      windowFloatingElements.push(floatElementWinRate)
    }
  }

  // For annualized volatility
  if (selectedControlKey.value == 'annualized_volatility') {
    if (annualizedResponse.value) {
      // Volatility
      const floatElementVolatility: FloatingElement = {
        key: `volatility_${uniqueKey}`,
        title: `Annualized Volatility`,
        type: 'analysis_line',
        analysis_data: {
          colors: ['#BD8B9C'],
          periods: annualizedResponse.value.periods,
          isMoney: true,
          labelSuffix: '',
          dataSeries: [
            {
              name: 'Annualized Volatility',
              data: annualizedResponse.value.analysis.map(
                (item) => item.annualized_volatility || 0,
              ),
            },
          ],
        },
      }

      windowFloatingElements.push(floatElementVolatility)

      // Sharpe
      const floatElementSharpe: FloatingElement = {
        key: `sharpe_${uniqueKey}`,
        title: `Annualized Sharpe`,
        type: 'analysis_line',
        analysis_data: {
          colors: ['#DC602E'],
          periods: annualizedResponse.value.periods,
          isMoney: false,
          labelSuffix: '',
          dataSeries: [
            {
              name: 'Annualized Sharpe',
              data: annualizedResponse.value.analysis.map(
                (item) => item.sharpe_ratio || 0,
              ),
            },
          ],
        },
      }

      windowFloatingElements.push(floatElementSharpe)
    }
  }
}

const setContainerContent = () => {
  const currentFloatingElement = windowFloatingElements.filter(
    (item) => item.key == currentSelectFloatingWindow.value,
  )

  if (currentFloatingElement[0]) {
    const floatingElements = currentFloatingElement[0]

    const dimension: any = {
      '1': ['col-span-12'],
      '2': ['col-span-12', 'col-span-12'],
      '3': ['col-span-6', 'col-span-6', 'col-span-12'],
      '4': ['col-span-6', 'col-span-6', 'col-span-6', 'col-span-6'],
    }

    // For chart data

    if (floatingElements.type == 'charts') {
      windowChartContainers.length = 0

      const chartDimension = dimension[`${floatingElements.chart_data?.length}`]

      floatingElements.chart_data?.forEach((chartData, index) => {
        windowChartContainers.push({
          customClass: chartDimension[index],
          props: {
            instrument: chartData.instrument,
            defaultZoom: chartDimension[index] == 'col-span-6' ? 12 : 8,
            chart_data: chartData.sections,
            full_data: floatingElements.chart_data,
          },
          type: 'chart',
          title: `${chartData.instrument}`,
        })
      })
    }

    // For analysis

    if (floatingElements.type == 'analysis_bar') {
      windowChartContainers.length = 0
      windowChartContainers.push({
        customClass: 'col-span-12',
        props: {
          analysis_chart_data: floatingElements.analysis_data,
        },
        type: 'bar_chart',
        title: ``,
      })
    }

    if (floatingElements.type == 'analysis_line') {
      console.log(floatingElements.analysis_data)
      windowChartContainers.length = 0
      windowChartContainers.push({
        customClass: 'col-span-12',
        props: {
          analysis_chart_data: floatingElements.analysis_data,
        },
        type: 'line_chart',
        title: ``,
      })
    }
  }
}

const setWindowData = async () => {
  const fetchData = () => {
    windowChartContainers.length = 0
    if (selectedControlKey.value == 'day_trade_analysis') {
      const requestID = makeid(10)

      controlOptions.forEach((item) => {
        if (item.key == 'day_trade_analysis') {
          item.request_id = requestID
        }
      })

      controlOptions[2].loading = true

      currentLoadingRequestID.value = requestID

      $api.trade.weekTrade(
        {
          endWeekDate:
            moment(controlOptions[2].settings[0].value)
              .endOf('week')
              .format('YYYY-MM-DD') || '',
          back_weeks: 1,
          fallback_instrument: 'SPX500',
          instruments: ['US30', 'NAS100', 'SPX500'],
          use_firsttick: true,
          is_live: false,
          today: '',
          activeDays: [],
          targetDay: moment(controlOptions[2].settings[0].value).format(
            'YYYY-MM-DD',
          ),
        },
        requestID,
      )

      // Add loading window

      windowChartContainers.push(
        ...[
          {
            customClass: 'col-span-12',
            props: {
              content: 'Analysis beginning...',
              info_type: 'info',
            },
            type: 'loading',
            title: 'SPX500 Trade',
          },
        ],
      )
    }

    if (selectedControlKey.value == 'win_rate') {
      const requestID = makeid(10)

      controlOptions.forEach((item) => {
        if (item.key == 'win_rate') {
          item.request_id = requestID
        }
      })

      controlOptions[0].loading = true

      currentLoadingRequestID.value = requestID

      const selectedPeriod = controlOptions[0].settings[1].period

      let backWeek = controlOptions[0].settings[1].back_count

      if (selectedPeriod == 'day') {
        backWeek = Math.round(controlOptions[0].settings[1].back_count / 7)
      }

      if (selectedPeriod == 'month') {
        backWeek = controlOptions[0].settings[1].back_count * 4
      }

      if (selectedPeriod == 'week') {
        backWeek = controlOptions[0].settings[1].back_count
      }

      $api.trade.tradeAnalysis(
        {
          endWeekDate:
            moment(controlOptions[0].settings[0].value)
              .endOf('week')
              .format('YYYY-MM-DD') || '',
          back_weeks: parseFloat(backWeek.toString()),
          fallback_instrument: 'SPX500',
          instruments: ['US30', 'NAS100', 'SPX500'],
          base_capital: parseFloat(
            controlOptions[0].settings[2].value.replace(/,/g, ''),
          ),
          key: 'win_rate',
          period: controlOptions[0].settings[1].period,
        },
        requestID,
      )

      // Add loading window

      windowChartContainers.push(
        ...[
          {
            customClass: 'col-span-12',
            props: {
              content: 'Analysis beginning...',
              info_type: 'info',
            },
            type: 'loading',
            title: 'SPX500 Trade',
          },
        ],
      )
    }

    if (selectedControlKey.value == 'annualized_volatility') {
      const requestID = makeid(10)

      controlOptions.forEach((item) => {
        if (item.key == 'annualized_volatility') {
          item.request_id = requestID
        }
      })

      controlOptions[1].loading = true

      currentLoadingRequestID.value = requestID

      const selectedPeriod = controlOptions[1].settings[1].period

      let backWeek = controlOptions[1].settings[1].back_count

      if (selectedPeriod == 'day') {
        backWeek = Math.round(controlOptions[1].settings[1].back_count / 7)
      }

      if (selectedPeriod == 'month') {
        backWeek = controlOptions[1].settings[1].back_count * 4
      }

      if (selectedPeriod == 'week') {
        backWeek = controlOptions[1].settings[1].back_count
      }

      $api.trade.tradeAnalysis(
        {
          endWeekDate:
            moment(controlOptions[1].settings[0].value)
              .endOf('week')
              .format('YYYY-MM-DD') || '',
          back_weeks: parseFloat(backWeek.toString()),
          fallback_instrument: 'SPX500',
          instruments: ['US30', 'NAS100', 'SPX500'],
          base_capital: parseFloat(
            controlOptions[1].settings[2].value.replace(/,/g, ''),
          ),
          key: 'annualized',
          period: controlOptions[1].settings[1].period,
        },
        requestID,
      )

      // Add loading window

      windowChartContainers.push(
        ...[
          {
            customClass: 'col-span-12',
            props: {
              content: 'Analysis beginning...',
              info_type: 'info',
            },
            type: 'loading',
            title: 'SPX500 Trade',
          },
        ],
      )
    }
  }

  if (!socketIsOpen.value) {
    connectToWebSocket().then((response) => {
      if (response) {
        fetchData()
      }
    })
  } else {
    fetchData()
  }
}

const setDefaultInfoContent = () => {
  windowChartContainers.length = 0

  if (selectedControlKey.value == 'win_rate') {
    windowChartContainers.push(
      ...[
        {
          customClass: 'col-span-12',
          props: {
            content: `
              <span class="text-black text-xl">Welcome to 3Tickers.</span> <br> <br>
              <span>To see the strategy's <span class="text-black font-semibold">Win Rate</span> base on your investment capital, enter your settings at the bottom left corner of this page.</span> <br>
              <span><span class="text-black font-semibold">Note:</span> Analysis is done <span class="text-red-400">backwards</span> in time.</span><br><br>
              <span><span class="text-blue-500 font-semibold">End Date:</span> The date when the analysis should end. (Only analysis from January 9, 2023 till date is available) </span><br>
              <span><span class="text-blue-500 font-semibold">Analysis Period:</span> The amount of back dated period of analysis that you want to see. E.g 12 Months</span><br>
              <span><span class="text-blue-500 font-semibold">Base Capital:</span> Your investment base capital. Minimum is $5,000 </span>
              `,
          },
          type: 'info',
          title: '',
        },
      ],
    )
  }

  if (selectedControlKey.value == 'annualized_volatility') {
    windowChartContainers.push(
      ...[
        {
          customClass: 'col-span-12',
          props: {
            content: `
              <span>To see the strategy's <span class="text-black font-semibold">Annualized Volatility</span> and <span class="text-black font-semibold">Annulaized Sharpe Ratio</span> base on your investment capital, <br> enter your settings on the bottom left corner of this page.</span> <br>
              <span><span class="text-black font-semibold">Note:</span> Analysis is done <span class="text-red-400">backwards</span> in time.</span><br><br>
              <span><span class="text-blue-500 font-semibold">End Date:</span> The date when the analysis should end. (Only analysis from January 9, 2023 till date is available) </span><br>
              <span><span class="text-blue-500 font-semibold">Analysis Period:</span> The amount of back dated period of analysis that you want to see. e.g 12 Months </span><br>
              <span><span class="text-blue-500 font-semibold">Base Capital:</span> Your investment base capital. Minimum is $5,000 </span>
              `,
          },
          type: 'info',
          title: '',
        },
      ],
    )
  }

  if (selectedControlKey.value == 'day_trade_analysis') {
    windowChartContainers.push(
      ...[
        {
          customClass: 'col-span-12',
          props: {
            content: `
              <span><span class="text-blue-500 font-semibold">Day trade Analysis:</span> This shows details of the trade taken by the system on the choosen <span class="font-semibold text-purple-500">market date</span>.</span><br>
              `,
          },
          type: 'info',
          title: '',
        },
      ],
    )
  }
}

export {
  controlOptions,
  windowChartContainers,
  currentLoadingRequestID,
  selectedControlKey,
  dayTradeResponse,
  windowFloatingElements,
  currentSelectFloatingWindow,
  winRateResponse,
  annualizedResponse,
  setWindowData,
  setContainerContent,
  setWindowContent,
  setDefaultInfoContent,
}
