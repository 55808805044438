<template>
  <div class="w-full flex flex-col bg-[#e9eae9] h-full absolute top-0 left-0">
    <div
      class="w-full h-full py-5 px-3 chartGlobal z-20"
      :id="`chartContainer${uniqueCode}`"
    ></div>
    <!-- Loading -->
    <span
      class="bg-white rounded-br-[5px] px-4 py-2 absolute top-[0%] left-[0%] z-20"
      v-if="dataIsFetching"
    >
      <normal-text :color="'text-gray-600'"> Loading... </normal-text>
    </span>

    <!-- Legends -->
    <div
      class="w-full flex flex-row items-center justify-center space-x-2 absolute top-0 left-0 z-20"
    >
      <div
        class="py-2 px-2 bg-white flex space-x-1 items-center cursor-pointern rounded-b-[6px]"
        v-for="(item, index) in currentChartSection?.labels?.filter(
          (item) => item.timeframe == chartSetup.interval
        )"
        :key="index"
        @click="focusOnLable(item.candles[0])"
      >
        <div
          :class="`h-[8px] w-[8px]  `"
          :style="`background-color: ${item.color};`"
        ></div>
        <normal-text class="!text-[10px]">
          {{ item.title }}
        </normal-text>
      </div>
    </div>

    <!-- Chart title -->
    <span
      class="bg-purple-200 rounded-bl-[5px] px-1 py-1 absolute top-[0%] right-[0%] z-20"
      v-if="instrumentOptions.length"
    >
      <f-select
        :customClass="'border-transparent !w-[70px]'"
        :options="instrumentOptions"
        :paddings="'px-1 py-1'"
        v-model="selectedInstrument"
      >
      </f-select>
    </span>

    <!-- Time interval -->
    <div
      class="absolute bottom-[0%] right-[0%] rounded-tl-[5px] py-1 bg-purple-50 px-2 flex flex-row items-center space-x-1 z-50"
    >
      <template v-for="(interval, index) in possibleIntervals" :key="index">
        <div
          :class="`h-[30px] w-[39px] flex flex-row items-center justify-center rounded-[2px] ${
            chartSetup.interval == interval.key ? 'bg-purple-200' : ''
          } hover:bg-purple-200 cursor-pointer`"
          v-if="interval.show"
          :key="index"
          @click="setSection(interval.key)"
        >
          <normal-text :color="'text-gray-600'">
            {{ interval.name }}
          </normal-text>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { CandlestickChart } from "../composables/CandlestickChart";
import moment from "moment-timezone";
import { ChartData } from "../modules";
import { NormalText } from "./Typography";
import { $api } from "../services/api";
import { ChartSection, SelectOption } from "../types";
import FSelect from "./Form/select.vue";

export default defineComponent({
  components: {
    NormalText,
    FSelect,
  },
  props: {
    instrument: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      dafault: "",
    },
    defaultZoom: {
      type: Number,
      default: 4,
    },
    data: {
      type: Array as () => ChartSection[],
      default: () => [],
    },
    fullData: {
      type: Array as () => {
        instrument: string;
        sections: ChartSection[];
      }[],
      default: () => [],
    },
  },
  setup(props) {
    const chartFullElement = ref();

    const uniqueCode = Math.floor(Math.random() * 10000);

    const currentChartData = ref<ChartData[]>([]);

    const currentChartSection = ref<ChartSection>();

    const chartData = ref<ChartSection[]>([]);

    const selectedInstrument = ref("");

    const preventWatch = ref(true);

    // const dataCursors = reactive({
    //   current_start_index: 0,
    //   current_end_index: 0,
    // });

    const instrumentOptions = reactive<SelectOption[]>([]);

    const chartElement = ref();

    const chartSetup = reactive<{
      interval:
        | "1m"
        | "5m"
        | "15m"
        | "30m"
        | "1h"
        | "2h"
        | "4h"
        | "1d"
        | "1wk"
        | "1mo";
      start: string;
      end: string;
    }>({
      interval: "5m",
      start: "",
      end: "",
    });
    const dataIsFetching = ref(false);

    const InstrumentData = reactive<
      {
        Date: string;
        Open: number;
        High: number;
        Low: number;
        Close: number;
        "Adj Close"?: number;
        Volume?: number;
        id: number;
      }[]
    >([]);

    const possibleIntervals = reactive<
      {
        name:
          | "1m"
          | "5m"
          | "15m"
          | "30m"
          | "1h"
          | "2h"
          | "4h"
          | "1d"
          | "1wk"
          | "1mo";
        key:
          | "1m"
          | "5m"
          | "15m"
          | "30m"
          | "1h"
          | "2h"
          | "4h"
          | "1d"
          | "1wk"
          | "1mo";
        timeline: string;
        intraDay: boolean;
        maxDays: number;
        show: boolean;
      }[]
    >([
      {
        name: "1m",
        key: "1m",
        timeline: "daytime",
        intraDay: true,
        maxDays: 10,
        show: false,
      },
      {
        name: "5m",
        key: "5m",
        timeline: "daytime",
        intraDay: true,
        maxDays: 15,
        show: false,
      },
      {
        name: "15m",
        key: "15m",
        timeline: "daytime",
        intraDay: true,
        maxDays: 43,
        show: false,
      },
      {
        name: "30m",
        key: "30m",
        timeline: "daytime",
        intraDay: true,
        maxDays: 83,
        show: false,
      },
      {
        name: "1h",
        key: "1h",
        timeline: "daytime",
        intraDay: true,
        maxDays: 167,
        show: false,
      },
      {
        name: "2h",
        key: "2h",
        timeline: "daytime",
        intraDay: true,
        maxDays: 333,
        show: false,
      },
      {
        name: "4h",
        key: "4h",
        timeline: "daytime",
        intraDay: true,
        maxDays: 667,
        show: false,
      },
      {
        name: "1d",
        key: "1d",
        timeline: "weekday",
        intraDay: true,
        maxDays: 4000,
        show: false,
      },
      {
        name: "1wk",
        key: "1wk",
        timeline: "false",
        intraDay: true,
        maxDays: 28000,
        show: false,
      },
    ]);

    const fromReload = ref(false);
    const currentCandleToFocus = ref(0);

    const isNumeric = (value: string) => {
      return /^-?\d+$/.test(value);
    };

    const chartState = reactive({
      leftBoundaryMet: false,
      rightBoundaryMet: false,
    });

    const maxDataCount = 2000;
    const additionalDataCount = 400;

    const fetchPriceAction = async () => {
      let endDate = chartSetup.end;

      if (chartSetup.interval == "1m") {
        endDate = moment(chartSetup.end)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }

      if (chartSetup.interval == "5m") {
        endDate = moment(chartSetup.end)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }

      if (chartSetup.interval == "15m") {
        endDate = moment(chartSetup.end)
          .add(1, "day")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }

      if (chartSetup.interval == "30m") {
        endDate = moment(chartSetup.end)
          .add(1, "day")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }

      if (chartSetup.interval == "1h") {
        endDate = moment(chartSetup.end)
          .add(2, "day")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }

      if (chartSetup.interval == "2h") {
        endDate = moment(chartSetup.end)
          .add(5, "day")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }

      if (chartSetup.interval == "4h") {
        endDate = moment(chartSetup.end)
          .add(8, "day")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }

      if (chartSetup.interval == "1d") {
        endDate = moment(chartSetup.end)
          .add(20, "day")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }

      if (chartSetup.interval == "1wk") {
        endDate = moment(chartSetup.end)
          .add(4, "week")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }

      let useFirstTick = false;

      if (chartSetup.interval == "1d" || chartSetup.interval == "1wk") {
        useFirstTick = true;
      }

      const selectedInteval = possibleIntervals.filter(
        (item) => item.key == chartSetup.interval
      );

      if (!dataIsFetching.value) {
        dataIsFetching.value = true;

        InstrumentData.length = 0;

        const martketData = await $api.DataSource.marketData({
          arithm: "substract",
          date: endDate,
          ticker: selectedInstrument.value,
          timeframe: selectedInteval[0].key,
          use_firsttick: useFirstTick,
          daysCount: selectedInteval[0].maxDays,
        });

        InstrumentData.push(...martketData.data);

        dataIsFetching.value = false;

        chartFullElement.value = null;
        setupChartData();
      }
    };

    const createOrUpdateChart = (svg: any = null) => {
      if (InstrumentData.length == 0) {
        return;
      }
      const chartContainer = document.getElementById(
        `chartContainer${uniqueCode}`
      );

      let timeFormat = "";

      let timeline = "";

      const selectedInteval = possibleIntervals.filter(
        (item) => item.key == chartSetup.interval
      );

      if (selectedInteval.length) {
        if (selectedInteval[0].timeline == "daytime") {
          timeFormat = "hh:mma";
        } else if (selectedInteval[0].timeline == "weekday") {
          timeFormat = "ddd MMM D hh:mma";
        } else if (selectedInteval[0].timeline == "month") {
          timeFormat = "MMM D,YY hh:mma";
        }

        timeline = selectedInteval[0].timeline;
      }

      if (chartContainer) {
        // clear chart content
        if (svg == null) {
          chartContainer.innerHTML = "";
        }

        const containerInfo = chartContainer.getBoundingClientRect();

        let useFirstTick = false;

        if (chartSetup.interval == "1d" || chartSetup.interval == "1wk") {
          useFirstTick = true;
        }

        const chartElement = CandlestickChart(
          svg,
          currentChartData.value,
          [],
          currentChartSection.value?.labels?.filter(
            (item) => item.timeframe == chartSetup.interval
          ) || [],
          {
            date: (d) => {
              if (isNumeric(d.Date)) {
                return parseInt(d.Date);
              } else {
                return useFirstTick
                  ? parseInt(
                      moment(d.Date)
                        .add(3, "hours")
                        .tz("America/New_York")
                        .format("x")
                    )
                  : parseInt(moment(d.Date).tz("America/New_York").format("x"));
              }
            },
            high: (d) => d.High,
            low: (d) => d.Low,
            open: (d) => d.Open,
            close: (d) => d.Close,
            yLabel: "↑ Price ($)",
            height: containerInfo.height,
            width: containerInfo.width,
            title: undefined,
            xDomain: undefined,
            yDomain: undefined,
            xTicks: undefined,
            xFormat: timeFormat,
            timeline,
            additionalDataCount,
            uniqueCode: uniqueCode,
            chartState,
            zoomValue: props.defaultZoom,
            lastCandleId: fromReload.value
              ? currentCandleToFocus.value
              : InstrumentData[InstrumentData.length - 1].id,
            fromReload: fromReload.value,
          }
        );

        return chartElement;
      }
    };

    const setUpChart = () => {
      const chartContainer = document.getElementById(
        `chartContainer${uniqueCode}`
      );

      // if (chartContainer) {
      //   chartContainer.innerHTML = "";
      // }

      const chartElement = createOrUpdateChart();
      if (chartElement) {
        if (chartElement.node) {
          chartContainer?.appendChild(chartElement.node);
        }
        chartFullElement.value = chartElement.element;
      }
    };

    const getMaxItemsAroundIndex = (array: any[], index: number): any[] => {
      const startIndex = Math.max(0, index - 249); // Ensure we don't go below index 0
      const endIndex = Math.min(array.length - 1, index + 250); // Ensure we don't exceed array length

      return array.slice(startIndex, endIndex + 1);
    };

    const setupChartData = async () => {
      let data = InstrumentData.slice(-maxDataCount);
      // dataCursors.current_start_index = InstrumentData.indexOf(data[0]);
      // dataCursors.current_end_index = InstrumentData.indexOf(
      //   data[data.length - 1]
      // );

      const currentCandleToFocusPriceAction = InstrumentData.filter(
        (item) => item.id == currentCandleToFocus.value
      );

      if (currentCandleToFocusPriceAction.length) {
        const candleIndex = InstrumentData.indexOf(
          currentCandleToFocusPriceAction[0]
        );
        data = getMaxItemsAroundIndex(InstrumentData, candleIndex);
      }

      currentChartData.value = data;
      setUpChart();

      if (preventWatch.value == true) {
        preventWatch.value = false;
      }
    };

    const setChartSection = async () => {
      if (currentChartSection.value) {
        chartSetup.interval = currentChartSection.value.timeframe;

        chartSetup.end = currentChartSection.value.endDate;

        await fetchPriceAction();
        setupChartData();
      }
    };

    // watch(chartState, () => {
    //   if (chartState.leftBoundaryMet) {
    //     if (dataCursors.current_start_index == 0) {
    //       return;
    //     }
    //     // add data forward but maintain the 500 max data count
    //     if (dataCursors.current_start_index - additionalDataCount >= 0) {
    //       const lastIndex = InstrumentData.length - 1;

    //       dataCursors.current_start_index =
    //         dataCursors.current_start_index - additionalDataCount;

    //       const newEndIndex =
    //         dataCursors.current_start_index + (maxDataCount - 1);
    //       dataCursors.current_end_index =
    //         newEndIndex < lastIndex ? newEndIndex : lastIndex;
    //     } else {
    //       dataCursors.current_start_index = 0;
    //       if (dataCursors.current_end_index > maxDataCount - 1) {
    //         dataCursors.current_end_index = maxDataCount - 1;
    //       }
    //     }

    //     currentChartData.value = InstrumentData.slice(
    //       dataCursors.current_start_index,
    //       dataCursors.current_end_index + 1
    //     );
    //   }

    //   if (chartState.rightBoundaryMet) {
    //     if (dataCursors.current_end_index == InstrumentData.length - 1) {
    //       return;
    //     }
    //     // add data backward but maintain the 500 max data count
    //     const lastIndex = InstrumentData.length - 1;
    //     if (dataCursors.current_end_index < lastIndex) {
    //       if (dataCursors.current_end_index + additionalDataCount > lastIndex) {
    //         dataCursors.current_end_index = lastIndex;
    //         dataCursors.current_start_index =
    //           lastIndex - (maxDataCount - 1) >= 0
    //             ? lastIndex - (maxDataCount - 1)
    //             : 0;
    //       } else {
    //         dataCursors.current_end_index =
    //           dataCursors.current_end_index + additionalDataCount;
    //         const newStartIndex =
    //           dataCursors.current_end_index - (maxDataCount - 1);
    //         dataCursors.current_start_index =
    //           newStartIndex >= 0 ? newStartIndex : 0;
    //       }
    //     }

    //     currentChartData.value = InstrumentData.slice(
    //       dataCursors.current_start_index,
    //       dataCursors.current_end_index + 1
    //     );
    //   }
    // });

    const focusOnLable = (id: number) => {
      fromReload.value = true;
      currentCandleToFocus.value = id;

      setupChartData();
    };

    const setSection = (timeFrame: string) => {
      currentChartSection.value = chartData.value.filter(
        (item) => item.timeframe == timeFrame
      )[0];
      setChartSection();
    };

    // watch(currentChartData, () => {
    //   if (!preventWatch.value) {
    //     createOrUpdateChart(chartFullElement.value);
    //     preventWatch.value = true;

    //     setTimeout(() => {
    //       preventWatch.value = false;
    //     }, 7000);
    //   }
    // });

    const setAvailableTimeframe = () => {
      // Set available timeframes
      const availableTimeFrames = chartData.value.map((item) => item.timeframe);

      // Make all invisible
      possibleIntervals.forEach((item) => (item.show = false));

      // Show only avaible ones
      possibleIntervals.forEach(
        (item) => (item.show = availableTimeFrames.includes(item.key))
      );
    };

    watch(selectedInstrument, async () => {
      chartData.value = props.fullData.filter(
        (item) => item.instrument == selectedInstrument.value
      )[0].sections;

      currentChartSection.value = chartData.value[0];
      fromReload.value = true;
      currentCandleToFocus.value =
        currentChartSection.value.labels[0].candles[0];

      setAvailableTimeframe();

      await setChartSection();
    });

    onMounted(async () => {
      if (props.data.length) {
        chartData.value = props.data;
        selectedInstrument.value = props.instrument;

        currentChartSection.value = chartData.value[0];
        fromReload.value = true;
        currentCandleToFocus.value =
          currentChartSection.value.labels[0].candles[0];

        await setChartSection();

        instrumentOptions.length = 0;

        props.fullData.forEach((item) => {
          instrumentOptions.push({
            key: item.instrument,
            value: item.instrument,
          });
        });

        setAvailableTimeframe();
      }
    });

    return {
      uniqueCode,
      possibleIntervals,
      chartSetup,
      dataIsFetching,
      currentChartSection,
      chartElement,
      instrumentOptions,
      selectedInstrument,
      CandlestickChart,
      setSection,
      focusOnLable,
    };
  },
});
</script>
<style>
.chartGlobal .x-axis line {
  stroke: #d0cdd7;
}

.chartGlobal .y-axis line {
  stroke: #d0cdd7;
}

.chartGlobal .mouse-line-vertical-text {
  background: red;
  border-radius: 5px;
  border: 2px solid blue;
  padding: 5px;
  font-size: 11px;
}

.chartGlobal .mouse-line-horizontal {
  stroke-dasharray: 5, 5;
}

.chartGlobal .mouse-line {
  stroke-dasharray: 5, 5;
}

.chartGlobal .mouse-line-horizontal-text {
  background: red;
  border-radius: 5px;
  border: 2px solid blue;
  padding: 5px;
  font-size: 11px;
}

/** For drawing **/
/* For Div element */

div.sample-div {
  position: absolute;
  top: 12%;
  left: 7%;
}
/* For script*/

rect.rect-main {
  stroke: #d32f2f;
  stroke-width: 2;
  stroke-opacity: 0.5;
}

g.active rect {
  stroke-opacity: 1;
}

div.sample-div {
  position: block;
}

.rectEdge {
  fill: #303f9f;
  opacity: 0;
}

.rectEdge:hover {
  cursor: move;
}

.rectCorner {
  fill: #668d3c;
  opacity: 0;
}

.rectCorner.nwse:hover {
  cursor: nwse-resize;
}

.rectCorner.nesw:hover {
  cursor: nesw-resize;
}

.cornerEdge.debug {
  opacity: 0.25;
}
</style>
