import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.loading,
    class: _normalizeClass(`focus:outline-none shadow-sm rounded-md flex flex-row space-x-2 items-center ${
      _ctx.loading ? 'opacity-75' : ''
    } lg:text-sm mdlg:text-[12px] text-xs justify-center ${_ctx.padding} ${_ctx.bgColor} ${_ctx.textColor} ${_ctx.customClass}`)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}