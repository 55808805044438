<template>
  <button
    :disabled="loading"
    :class="`focus:outline-none shadow-sm rounded-md flex flex-row space-x-2 items-center ${
      loading ? 'opacity-75' : ''
    } lg:text-sm mdlg:text-[12px] text-xs justify-center ${padding} ${bgColor} ${textColor} ${customClass}`"
  >
    <slot />
  </button>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    bgColor: {
      type: String,
      default: "bg-[#5F0A87]",
    },
    textColor: {
      type: String,
      default: "text-white",
    },
    customClass: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "py-2 px-4 ",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  name: "FButton",
});
</script>
