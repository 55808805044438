import { AxiosResponse } from 'axios'
import { ReadOnlyApiService } from './common/ReadOnlyService'
import { PriceActionReaction } from '@/types/Trade'
import { TradeSummary } from '@/types/TradeInitiator'
import { TradeAnalysisResponse } from '@/types/DayTrade'

export class TradeApi extends ReadOnlyApiService {
  constructor() {
    // this is equivalent to your base_url/sample
    super('trades')
  }

  public async dayDirection(data: {
    date: string
    allInstrument: string[]
    use_firsttick: boolean
    is_live: boolean
  }): Promise<
    AxiosResponse<{
      analysis: {
        instrument: string
        directions: {
          datetime: string
          reaction_details: PriceActionReaction
        }[]
      }[]
      leading_instrument: string
    }>
  > {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        this.getUrl() + '/day-direction',
        data,
      )

      return response
    } catch (err) {
      this.handleErrors(err)
      // you can handle request specific error here
      throw err
    }
  }

  public async dayTrade(
    data: {
      date: string
      instruments: string[]
      latest_direction_reaction: string
      fallback_instrument: string
      use_firsttick: boolean
      is_live: boolean
      focus_on_today: boolean
    },
    request_id: string,
  ): Promise<
    AxiosResponse<{
      profit: number
      loss: number
      latest_direction_reaction: string
      total_trades: number
      lost_trades: number
      profit_trades: number
      leading_instrument: string
      trade_info: {
        instrument: string
        currentTrades: {
          entry_time: string
          summary: TradeSummary
        }[]
      }[]
    }>
  > {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        this.getUrl() + '/day-trade',
        data,
        {
          headers: {
            requestId: request_id,
          },
        },
      )

      return response
    } catch (err) {
      this.handleErrors(err)
      // you can handle request specific error here
      throw err
    }
  }

  public async weekTrade(
    data: {
      endWeekDate: string
      instruments: string[]
      fallback_instrument: string
      back_weeks: number
      use_firsttick: boolean
      today: string
      is_live: boolean
      activeDays: string[]
      targetDay?: string | undefined
    },
    request_id: string,
  ): Promise<
    AxiosResponse<{
      profit: number
      loss: number
      total_trades: number
      lost_trades: number
      profit_trades: number
      today_leading_instrument: string
      target_day_data?: {
        instrument: string
        currentTrades: {
          entry_time: string
          summary: TradeSummary
        }[]
      }
      all_trade_data?: {
        instrument: string
        currentTrades: {
          entry_time: string
          summary: TradeSummary
        }[]
      }[]
    }>
  > {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        this.getUrl() + '/backweek-trades',
        data,
        {
          headers: {
            requestId: request_id,
          },
        },
      )

      return response
    } catch (err) {
      this.handleErrors(err)
      // you can handle request specific error here
      throw err
    }
  }

  public async tradeAnalysis(
    data: {
      endWeekDate: string
      instruments: string[]
      fallback_instrument: string
      back_weeks: number
      period: 'day' | 'week' | 'month'
      base_capital: number
      key: string
    },
    request_id: string,
  ): Promise<AxiosResponse<TradeAnalysisResponse>> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        this.getUrl() + '/analysis',
        data,
        {
          headers: {
            requestId: request_id,
          },
        },
      )

      return response
    } catch (err) {
      this.handleErrors(err)
      // you can handle request specific error here
      throw err
    }
  }
}
