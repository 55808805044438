import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col bg-[#e9eae9] h-full absolute top-0 left-0" }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 0,
  class: "bg-white rounded-br-[5px] px-4 py-2 absolute top-[0%] left-[0%] z-20"
}
const _hoisted_4 = { class: "w-full flex flex-row items-center justify-center space-x-2 absolute top-0 left-0 z-20" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 1,
  class: "bg-purple-200 rounded-bl-[5px] px-1 py-1 absolute top-[0%] right-[0%] z-20"
}
const _hoisted_7 = { class: "absolute bottom-[0%] right-[0%] rounded-tl-[5px] py-1 bg-purple-50 px-2 flex flex-row items-center space-x-1 z-50" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_normal_text = _resolveComponent("normal-text")!
  const _component_f_select = _resolveComponent("f-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "w-full h-full py-5 px-3 chartGlobal z-20",
      id: `chartContainer${_ctx.uniqueCode}`
    }, null, 8, _hoisted_2),
    (_ctx.dataIsFetching)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createVNode(_component_normal_text, { color: 'text-gray-600' }, {
            default: _withCtx(() => [
              _createTextVNode(" Loading... ")
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentChartSection?.labels?.filter(
          (item) => item.timeframe == _ctx.chartSetup.interval
        ), (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "py-2 px-2 bg-white flex space-x-1 items-center cursor-pointern rounded-b-[6px]",
          key: index,
          onClick: ($event: any) => (_ctx.focusOnLable(item.candles[0]))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`h-[8px] w-[8px]  `),
            style: _normalizeStyle(`background-color: ${item.color};`)
          }, null, 4),
          _createVNode(_component_normal_text, { class: "!text-[10px]" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.title), 1)
            ]),
            _: 2
          }, 1024)
        ], 8, _hoisted_5))
      }), 128))
    ]),
    (_ctx.instrumentOptions.length)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
          _createVNode(_component_f_select, {
            customClass: 'border-transparent !w-[70px]',
            options: _ctx.instrumentOptions,
            paddings: 'px-1 py-1',
            modelValue: _ctx.selectedInstrument,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedInstrument) = $event))
          }, null, 8, ["options", "modelValue"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.possibleIntervals, (interval, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (interval.show)
            ? (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(`h-[30px] w-[39px] flex flex-row items-center justify-center rounded-[2px] ${
            _ctx.chartSetup.interval == interval.key ? 'bg-purple-200' : ''
          } hover:bg-purple-200 cursor-pointer`),
                key: index,
                onClick: ($event: any) => (_ctx.setSection(interval.key))
              }, [
                _createVNode(_component_normal_text, { color: 'text-gray-600' }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(interval.name), 1)
                  ]),
                  _: 2
                }, 1024)
              ], 10, _hoisted_8))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}