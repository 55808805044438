import { AxiosResponse } from 'axios'
import { ReadOnlyApiService } from './common/ReadOnlyService'
import { PDArray } from '@/modules'

export class DataProcessingApi extends ReadOnlyApiService {
  constructor() {
    // this is equivalent to your base_url/sample
    super('data-processing')
  }

  public async extractPDArrays(
    data: {
      ticker: string
      timeframe:
        | '1mo'
        | '1wk'
        | '1d'
        | '4h'
        | '1h'
        | '2h'
        | '30m'
        | '15m'
        | '5m'
        | '1m'
      date: string
      pdArraysToDetect: PDArray['type'][]
      arithm: 'add' | 'substract'
      daysCount?: number | undefined
      use_firsttick: boolean
    },
    request_id: string,
  ): Promise<AxiosResponse<PDArray[]>> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        this.getUrl() + '/extract-pdarrays',
        data,
        {
          headers: {
            requestId: request_id,
          },
        },
      )

      return response
    } catch (err) {
      this.handleErrors(err)
      // you can handle request specific error here
      throw err
    }
  }
}
