import { convertToMoney } from '.'

export const getColumnChartConfig = (
  themeColors: {
    themeSecondaryTextColor: string
    themeBorderColor: string
    themeDisabledTextColor: string
  },
  isMoney = false,
  xLabels: string[] = [],
  suffix = '',
  columnColors: string[],
) => {
  const {
    themeSecondaryTextColor,
    themeBorderColor,
    themeDisabledTextColor,
  } = themeColors

  return {
    chart: {
      offsetX: -10,
      stacked: false,
      parentHeightOffset: 0,
      toolbar: { show: false },
    },
    fill: { opacity: 1 },
    dataLabels: { enabled: false },

    colors: [columnColors[0], columnColors[1], columnColors[2]],
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      fontSize: '13px',
      labels: { colors: themeSecondaryTextColor },
      markers: {
        offsetY: 1,
        offsetX: -3,
      },
      itemMargin: {
        vertical: 3,
        horizontal: 10,
      },
    },

    plotOptions: {
      bar: {
        columnWidth: '40%',
        borderRadius: 0,
      },
    },
    grid: {
      borderColor: themeBorderColor,
      xaxis: {
        lines: { show: true },
      },
    },
    yaxis: {
      labels: {
        style: { colors: themeDisabledTextColor, fontSize: '0.8125rem' },
        formatter: (value: any) => {
          return `${convertToMoney(value, true, isMoney ? 'usd' : '')}${suffix}`
        },
      },
    },
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { color: themeBorderColor },
      categories: xLabels,
      crosshairs: {
        stroke: { color: themeBorderColor },
      },
      labels: {
        style: { colors: themeDisabledTextColor, fontSize: '0.8125rem' },
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          plotOptions: {
            bar: {
              columnWidth: '35%',
            },
          },
        },
      },
    ],
  }
}

export const getAreaChartSplineConfig = (
  themeColors: {
    themeSecondaryTextColor: string
    themeBorderColor: string
    themeDisabledTextColor: string
  },
  areaColors: any,
  isMoney = false,
  xLabels: string[] = [],
) => {
  const {
    themeSecondaryTextColor,
    themeBorderColor,
    themeDisabledTextColor,
  } = themeColors

  return {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
    },
    tooltip: { shared: true },
    dataLabels: { enabled: false },
    stroke: {
      show: true,
      curve: 'smooth',
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      fontSize: '13px',
      labels: { colors: themeSecondaryTextColor },
      markers: {
        offsetY: 1,
        offsetX: -3,
      },
      itemMargin: {
        vertical: 3,
        horizontal: 10,
      },
    },

    colors: areaColors || [
      '#9D4EDD',
      '#FFAA00',
      '#6A76E5',
      '#80D89B',
      '#EA5959',
      '#53C1FF',
      '#C19875',
      '#A3CEF1',
    ],
    fill: {
      opacity: 0.5,
      type: 'solid',
    },

    grid: {
      show: true,
      borderColor: themeBorderColor,
      xaxis: {
        lines: { show: true },
      },
    },
    yaxis: {
      labels: {
        style: { colors: themeDisabledTextColor, fontSize: '0.8125rem' },
        formatter: (value: any) => {
          return isMoney
            ? `${convertToMoney(value, true, isMoney ? '' : '')}`
            : value
        },
      },
    },
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { color: themeBorderColor },
      crosshairs: {
        stroke: { color: themeBorderColor },
      },
      labels: {
        style: { colors: themeDisabledTextColor, fontSize: '0.8125rem' },
      },
      categories: xLabels,
    },
  }
}
