import currency from 'currency.js'
import moment from 'moment'

const convertToMoney = (
  float: any,
  withZeros = true,
  currencyType = 'usd',
  withSymbol = true,
  customSymbol = '',
) => {
  let currencySymbol = ''
  if (currencyType == 'usd') {
    currencySymbol = '$'
  } else if (currencyType == 'ngn') {
    currencySymbol = '₦ '
  }
  if (!withSymbol) {
    currencySymbol = ''
  }

  if (customSymbol) {
    currencySymbol = customSymbol
  }

  if (withZeros) {
    return currency(float, {
      separator: ',',
      symbol: currencySymbol,
    }).format()
  } else {
    return (
      currencySymbol +
      new Intl.NumberFormat().format(parseFloat(parseFloat(float).toFixed(5)))
    )
  }
}

const makeid = (length: number) => {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const isNumeric = (value: string) => {
  return /^-?\d+$/.test(value)
}

const dateToNumeric = (date: any) => {
  if (isNumeric(date)) {
    return parseInt(date)
  } else {
    return parseInt(moment(date).tz('America/New_York').format('x'))
  }
}

const debounce = (
  method = () => {
    //
  },
  delay = 500,
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (typeof window.LIT !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    clearTimeout(window.LIT)
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.LIT = setTimeout(() => {
    method()
  }, delay)
}

const splitArrayToChunk = (inputArray: any[], perChunk = 200) => {
  return inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}

const isString = (x: any) => {
  return Object.prototype.toString.call(x) === '[object String]'
}

const searchArray = (arr: any[], searchKey: string) => {
  return arr.filter((obj) => {
    return Object.keys(obj).some((key) => {
      return isString(obj[key]) ? obj[key].includes(searchKey) : false
    })
  })
}

export {
  convertToMoney,
  debounce,
  splitArrayToChunk,
  searchArray,
  makeid,
  dateToNumeric,
}
