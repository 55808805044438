import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(`
  ${_ctx.customClass} ${_ctx.color}
  ${_ctx.size == 'base' ? '!text-xs' : ''}
  ${_ctx.size == 'small' ? ' text-xs' : ''}`)
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.htmlContent)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          innerHTML: _ctx.htmlContent,
          class: _normalizeClass(`prose-sm prose !text-shop_dark_300 prose-ul:!mb-0 ${_ctx.htmlStyle}`)
        }, null, 10, _hoisted_1))
      : _createCommentVNode("", true)
  ], 2))
}